import gql from "graphql-tag";
import { print } from "graphql";
import CryptoJS from "crypto-js";


export const generateDynamicQuery = (variables: any, includedFields: any) => {
  const fields = `${includedFields},\n_id \n subscriberId\nisDeleted`;
  // console.log('fields:', fields);
  const query = gql`
query FetchBeneficiaryData(
  $page: Float,
  $limit: Float,
  $findEmptyDetailsBen: [String!],
  $findFullyCompletedBeneficiaries:[String!],
  $beneficiary: Boolean,
  $subscriber: Boolean,
  $companyName:String,
  $email:String,
  $mobile:String,
  $name:String,
  $benRelation:String,
  $createdFrom:DateTime,
  $createdTo:DateTime,
  $updatedFrom:DateTime,
  $updatedTo:DateTime,
  $benId:String
  $downloadExcel:Boolean
  $city:String
  $pinCode:String
  $activatedBen:Boolean
  $notActivatedBen:Boolean
  $sameAsSubscriberNumber:Boolean
  $qrDispatched: Boolean
  $qrNotDispatched: Boolean
  $notSameAsSubscriberNumber:Boolean
  $isAllDataRequired:Boolean
  $validEmail:Boolean
) {
  
  beneficiaries: yourQuery(
    page: $page,
    limit: $limit,
    findEmptyDetailsBen: $findEmptyDetailsBen,
    findFullyCompletedBeneficiaries:$findFullyCompletedBeneficiaries,
    companyName: $companyName,
    email:$email,
    mobile:$mobile,
    name:$name,
    beneficiary:$beneficiary,
    subscriber:$subscriber,
    benRelation:$benRelation,
    createdFrom:$createdFrom,
    createdTo:$createdTo,
    updatedFrom:$updatedFrom,
    updatedTo:$updatedTo, 
    benId:$benId 
    downloadExcel:$downloadExcel
    city:$city
    pinCode:$pinCode
    activatedBen:$activatedBen
    notActivatedBen:$notActivatedBen
    sameAsSubscriberNumber:$sameAsSubscriberNumber
    qrDispatched:$qrDispatched
    qrNotDispatched:$qrNotDispatched
    notSameAsSubscriberNumber:$notSameAsSubscriberNumber
    isAllDataRequired:$isAllDataRequired
    validEmail:$validEmail
  ) {
    hasNextPage
  count
    data{
      ${fields}   
    }
    
  }
  
 
 
}
`;
console.log('variables:', fields);
  // const queryString = print(query);
  const encryptedQuery = CryptoJS.AES.encrypt(print(query), 'udhaya').toString();
  // console.log('queryString:', queryString);
  return { queryString:encryptedQuery, variables };
};
