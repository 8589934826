import React, { useEffect, useState } from 'react'
import SideBar from '../../GlobalComponent/SideBar/SideBar'
import style from './printing.module.css'
import { Box, Button, Flex, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Table, Tbody, Td, Textarea, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react'
import { createNewJob, getPrintingData } from './Actions/printing.action'
import { ShowAlert } from '../../GlobalComponent/Alerts/Alert'
import { useNavigate } from 'react-router-dom'

export const Printing: React.FC = () => {
    const [data, setData] = useState([]);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [jobName, setJobName] = useState("");
    const [description, setDescription] = useState("");
    const navigate = useNavigate();

    const clearData = () => {
        onClose();
        setJobName("");
        setDescription("");
    }
    
    const handleCreateNewJob = async () => {
        if (!jobName || jobName.trim() === ""|| !description || description.trim() === "") {
            return ShowAlert("warning", "All fields are required");
        }
        await createNewJob(jobName, description, setData);
        clearData();
    }
    useEffect(() => {
        getPrintingData(setData);
    }, [])

    return (
        <>
            <SideBar />
            <Box flex={1} p={1} mt={30} ml={30} bg="white">
                <div className={style.mainContainer}>
                    <Flex>
                        <Button onClick={onOpen} colorScheme='green'>Create Job</Button>
                    </Flex>
                    <div className={style.tableContainer}>
                        <Table variant="simple">
                            <Thead>
                                <Tr>
                                    <Th
                                        position="sticky"
                                        top={0}
                                        bgColor="white"
                                        zIndex="sticky"
                                    >
                                        Task Name
                                    </Th>
                                    <Th
                                        position="sticky"
                                        top={0}
                                        bgColor="white"
                                        zIndex="sticky"
                                    >
                                        Agent Id
                                    </Th>
                                    <Th
                                        position="sticky"
                                        top={0}
                                        bgColor="white"
                                        zIndex="sticky"
                                    >
                                        Created At
                                    </Th>
                                    <Th
                                        position="sticky"
                                        top={0}
                                        bgColor="white"
                                        zIndex="sticky"
                                    >
                                        Updated At
                                    </Th>
                                    <Th
                                        position="sticky"
                                        top={0}
                                        bgColor="white"
                                        zIndex="sticky"
                                    >
                                        Status
                                    </Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {data &&
                                    data.map((el: any) => (
                                        <Tr
                                            onClick={() => {
                                                return navigate('/printing-details/' + el._id)
                                            }}
                                            style={{ cursor: "pointer" }}
                                            key={el._id}
                                        >
                                            <Td>{el.name || "N/A"}</Td>
                                            <Td>{el.agentUserId || "N/A"}</Td>
                                            <Td>
                                                {new Date(el.createdAt).toLocaleString("en-GB", {
                                                    day: "2-digit",
                                                    month: "2-digit",
                                                    year: "numeric",
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                    hour12: true,
                                                })}
                                            </Td>
                                            <Td>
                                                {new Date(el.updatedAt).toLocaleString("en-GB", {
                                                    day: "2-digit",
                                                    month: "2-digit",
                                                    year: "numeric",
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                    hour12: true,
                                                })}
                                            </Td>
                                            <Td>{el.status}</Td>
                                        </Tr>
                                    ))}
                            </Tbody>
                        </Table>
                    </div>
                </div>
            </Box>

            {/* New Job Modal */}
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Create a new Job</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormLabel>Name</FormLabel>
                        <Input value={jobName} onChange={e => setJobName(e.target.value)} type='text' placeholder='Job Name' mb={"10px"} />
                        <FormLabel>Description</FormLabel>
                        <Textarea value={description} onChange={e => setDescription(e.target.value)} placeholder='Description' />
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='red' mr={3} onClick={clearData}>
                            Close
                        </Button>
                        <Button onClick={handleCreateNewJob} colorScheme='green'>Create Job</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}
