import style from "./CustomeTable.module.css"
interface DynamicTableProps {
  headers: any;
  benData: any;
  handleClick?:any;
  deleteComponent?:any;
}

export const DynamicTable: React.FC<DynamicTableProps> = ({
  headers,
  benData,
  handleClick,
  deleteComponent
}) => {
  console.log("values:", headers,benData);

  return (
    <div >
      <table >
        <thead className={style.tableHeader}>
          <tr>
            {headers.map((header: any) => (
              <th key={header}>{header}</th>
              ))}
              {deleteComponent&&<th>Action</th>}
          </tr>
        </thead>
        <tbody>
          {benData.map((key: any, index: any) => {
            const isValidDetailsNo = key["isValidDetails"] === "No";
            // console.log('isValidDetailsNo:', isValidDetailsNo);
            return <tr  style={{ backgroundColor: isValidDetailsNo  ? "pink" : "inherit" }} onClick={()=>handleClick(key._id,key)} key={index}>
              {headers.map((value: any) => (
                <td key={value}>{key[value]!=="undefined"?key[value]:"N/A"||"N/A"}</td>
              ))}
              {deleteComponent&&<td>{deleteComponent(key._id)}</td>}
            </tr>;
          })}
        </tbody>
      </table>
    </div>
  );
};
