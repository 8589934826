import { useEffect, useState } from "react";
import { separateBenDataForTable } from "./Action/actions";
import { DynamicTable } from "../../GlobalComponent/CustomTable/DynamicTable";
import { useNavigate } from "react-router-dom";

interface TableWrapperProps {
  data: any;
  component?: string;
}
export const TableWrapper: React.FC<TableWrapperProps> = ({
  data,
  component,
}) => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState<any>({
    headers: [],
    benData: [],
  });
  useEffect(() => {
    // console.log('data:###################', data);
    separateBenDataForTable({ data, setTableData });
  }, [data]);
  const handleNavigate = (jobId: any, data: any) => {
    if (component) {
      if (data.status === "pending") {
        return navigate(`/bulk_wa_message/${jobId}`);
      } else {
        return navigate(`/wa_response/${jobId}`);
      }
    } else {
      return navigate(`/bulk_wa_message/${jobId}`);
    }
  };

  return (
    <>
      <DynamicTable
        handleClick={handleNavigate}
        headers={tableData.headers}
        benData={tableData.benData}
      />
    </>
  );
};
