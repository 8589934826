import { Box, Button, Flex, Heading, Input, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import { Layout } from "../../GlobalComponent/Layout/Layout"
import { useEffect, useState } from "react"
import { createBulkEmailJob, getAllEmailJobs } from "../../Http/Index"
import { CustomModal } from "../../GlobalComponent/CustomModal/CustomModal";
import { useNavigate } from "react-router-dom";

export interface Recipient {
    benId: string;
    benEmail: string;
    benName: string;
    status: string;
}

export interface AgentData {
    agentId: string;
    agentName: string;
}

export interface AllEmailJobsInterface {
    _id: string;
    jobName: string;
    jobData: Recipient[];
    agentData: AgentData;
    templateId?: string;
    templateName?: string;
    createdAt: string;
    updatedAt: string;
    jobStatus: string;
}

export const EmailBulkMessage: React.FC = () => {
    const [allEmailJobs, setAllEmailJobs] = useState<AllEmailJobsInterface[] | []>([])
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [jobName, setJobName] = useState("")
    const [isJobCreationLoading, setIsJobCreationLoading] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        (async () => {
            await getAllJobs()
        })();
    }, [])

    const getAllJobs = async () => {
        try {
            let allJobData = await getAllEmailJobs();
            setAllEmailJobs(allJobData.data)
        } catch (error) {
            console.log(error)
            alert("Technical Error while getting All Jobs")
        }
    }

    const handleModal = () => {
        setIsModalOpen(!isModalOpen)
    }

    const createJob = async () => {
        setIsJobCreationLoading(true)
        try {
            if (!jobName) {
                alert("Please provide job name")
                return
            }
            await createBulkEmailJob(jobName)
            setIsJobCreationLoading(false)
            await getAllJobs()
            handleModal()
        } catch (error) {
            console.log(error)
            alert("Technical Error, Please Try Again")
            setIsJobCreationLoading(false)
        }
    }

    const openJobData = (jobId: string) => {
        navigate(`${jobId}`)
    }

    return (
        <Layout>
            <>
                <Heading size={"lg"} textAlign={"center"}>
                    Send Email
                </Heading>

                <Flex mt={"20px"} justifyContent={"center"}>
                    <Button colorScheme="green" onClick={handleModal}>Create Job</Button>
                    {isModalOpen && < CustomModal modalTitle="Create Job" handleCloseModal={handleModal}>
                        <Box textAlign={"left"} justifyContent={"left"} width={"100%"}>
                            <Input type="text" onChange={(e) => setJobName(e.target.value)}></Input>
                            <Button colorScheme="green" mt={"20px"} onClick={createJob} isDisabled={isJobCreationLoading}>Create Job</Button>
                        </Box>
                    </CustomModal>}
                </Flex>

                <Table variant="unstyled" mt={"30px"}>
                    <Thead>
                        <Tr>
                            <Th>JOB NAME</Th>
                            <Th>AGENT</Th>
                            <Th>CREATED AT</Th>
                            <Th>UPDATED AT</Th>
                            <Th>STATUS</Th>
                        </Tr>
                    </Thead>
                    <Tbody >
                        {allEmailJobs.length > 0 &&
                            allEmailJobs.map((item) => {
                                return (
                                    <Tr onClick={(e) => openJobData(item._id)}>
                                        <Td>{item.jobName}</Td>
                                        <Td>{item.agentData.agentName}</Td>
                                        <Td>{item.createdAt}</Td>
                                        <Td>{item.updatedAt}</Td>
                                        <Td>{item.jobStatus}</Td>
                                    </Tr>
                                )
                            })
                        }
                    </Tbody>
                </Table>
            </>
        </Layout >
    )
}