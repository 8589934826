import { getBenList, getWaJobById } from "../../../Http/Index";

export const getJobByJobId = async (jobId: any) => {
  if (jobId) {
    try {
      const res = await getWaJobById(jobId);
      return res.data.data;
    } catch (e) {
      throw e;
    }
  }
};

export const getManyBenData = async (benIds: any) => {
  const res = separateBenId(benIds);
  const payload = {
    benId: res,
  };
  const getBenData = await getManyBens(payload);
  return getBenData;
};

export const separateBenId = (benData: any) => {
  return benData.map((benFields: any) => {
    return benFields.benId;
  });
};

export const getManyBens = async (benIds: any) => {
  // console.log("benIds:", benIds);
  if (benIds) {
    try {
      const res = await getBenList(benIds);
      return res.data.data;
    } catch (e) {
      throw e;
    }
  }
};

export const buildDynamicTableData = ({ benData, templateVariables }: any) => {
  let values: any = [];
  //  console.log('benData:', benData,templateVariables)
  let mandatoryFields = Object.keys(benData[0]?.personalInfo).filter(
    (personalInfoField: any) =>
      !["dateOfBirth", "gender", "image","languages"].includes(personalInfoField)
  );
  // console.log("mandatoryFields:", mandatoryFields);
  benData.forEach((benFields: any,i:number) => {
   
    values.push({
      ...benFields.personalInfo,
      Sno:i+1,
      mobile: benFields?.personalInfo?.mobile?.number || "",
      whatsappMobile: benFields?.personalInfo?.whatsappMobile?.number || "",
      _id:benFields._id,
      ...( 
        !benFields?.personalInfo?.whatsappMobile?.number && !benFields?.personalInfo?.mobile?.number ?
            { isValidDetails: "No" } :
            { isValidDetails: "Yes" }
    )
    });
  });
  mandatoryFields.push("isValidDetails");
  mandatoryFields.unshift("Sno");
  // console.log('values:', values,mandatoryFields);
  return {
    header: mandatoryFields,
    benData: values,
  };
};

export const WaStatusChecker = (waJobData: any) => {
  // if(!waJobData?.jobData?.length){
  //   return false
  // }
  if(waJobData?.jobData?.length){
    let status = false;
    //eslint-disable-next-line
    waJobData?.jobData?.forEach((job: any) => {   
//if any one wa message is success then it goes to wa response page//
      if (job?.status === "Success" ) {
        status=true
        return
      } 
       
    });

    return status;
  }else{
    return false
  }
};
