import { deleteUser} from "../../Http/Index"
import { searchQueryFun } from "../ManageUser/ManageUserFun"
import { Result } from "./DeleteUsersInBulkTypes"

export const deleteUserData = async (payload: any) => {
    try {
        const response = await deleteUser(payload)
        // console.log(response)
        return response?.data?.message;
    }
    catch (error: any) {
        // console.log(error)
        return error.response?.data?.message;
    }
}

const validateEmail = (email: string): boolean => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
};

export const deleteUserIdsByEmails = async (emails: string[]) => {
    try {
        const results: Result[] = []
        let count: number = 1;
        for (const email of emails) {
            const result: Result = {
                srNo: count++,
                email: email,
                subscriberId: "",
                status: ""
            };

            if (validateEmail(email)) {
                //Query for users ids
                const response = await searchQueryFun({
                    email, "personalInfo.relationshipToSubscriber": "Self" });
                
                // finding all subscriber not deleted
                const beneficiaries = response?.benResponse?.filter((ben:{ isDeleted:boolean }) => ben.isDeleted === false);
                // console.log("email:"+email,beneficiaries);
                
                if (beneficiaries.length > 0) {
                    // checking for one subscriber for same email address
                    if (beneficiaries.length === 1) {
                        const userId = beneficiaries[0].userId;
                        result.subscriberId = beneficiaries[0].subscriberId;
                        const status: string = await deleteUserData({ userId });
                        results.push({ ...result, status: status });
                    }
                    else {
                        results.push({ ...result, status: "More than one subscriber found for the same email." });
                    }
                } else {
                    results.push({...result, status: "Email Not Found" });  
                }
            }
            else {
                results.push({...result, status: "Invalid Email" });
            }
        }

        return results;
    } catch (error) {
        console.log("An error occurred while deleting users.",(error as Error).message);
        throw new Error("An error occurred while deleting users.");
    }
};
