import { Layout } from "../../GlobalComponent/Layout/Layout"
import { ManageUserComponent } from "./ManageUserComponent"

export const ManageUser: React.FC = () => {
    return (
        <>
            <Layout>
                <ManageUserComponent />                
            </Layout>
        </>
    )
}