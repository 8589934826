import { adminUserLogoutAction } from "./Action/LogoutAction";

const Logout = async () => {
  try {

    await adminUserLogoutAction();
    // localStorage.removeItem("isLogin");
    // localStorage.removeItem("agentData");
    return;
  } catch (error) {
    return alert("Error occurred while logging out.");
  }
};

export default Logout;
