import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getBulkEmailTemplates, getSingleEmailJob, sendBulkEmail } from "../../../Http/Index";
import { AllEmailJobsInterface } from "../EmailBulkMessage";
import { Box, Button, Flex, Input, Select, Table, Tbody, Td, Text, Th, Thead, Tr, useToast } from "@chakra-ui/react";
import { Layout } from "../../../GlobalComponent/Layout/Layout";
import { BenFilterPopup } from "../../../GlobalComponent/BenFIlterPopup/BenFilterPoup";
import { handleCloseModal } from "../../BenAddressData/Action/actions";
import { MdDelete } from "react-icons/md";
import { updateBenToJob } from "../action/updateBenToJob";

interface BulkEmailTemplate {
    _id: string;
    name: string;
    description: string;
    subject: string;
    body: string;
    variables: string[];
    tags: string[];
    category: string;
    isDeleted: boolean;
    templateCategory: string;
}


export const SingleEmailJob: React.FC = () => {
    const { emailJobId } = useParams()
    const [jobData, setJobData] = useState<AllEmailJobsInterface | null>(null)
    const [bulkTemplates, setBulkTemplates] = useState<BulkEmailTemplate[] | []>([])
    const [selectedTemplate, setSelectedTemplate] = useState<BulkEmailTemplate>()
    const navigate = useNavigate()
    const [benFilter, setBenFilter] = useState<boolean>(false);
    const [isVisible, setIsVisible] = useState(false);
    // eslint-disable-next-line
    const [shouldRender, setShouldRender] = useState(false);
    const [inputVariables, setInputVariables] = useState<any>({})
    const [isAnyMailFailed, setIsAnyMailFailed] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [emailCount, setEmailCount] = useState<{ sent: number, error: number }>({ sent: 0, error: 0 })
    const toast = useToast()

    useEffect(() => {
        if (selectedTemplate && selectedTemplate.variables?.length > 0) {
            setShouldRender(true);
            setTimeout(() => setIsVisible(true), 10);
        } else {
            setIsVisible(false);
            setTimeout(() => setShouldRender(false), 300);
        }
    }, [selectedTemplate]);

    useEffect(() => {
        (async () => { getSingleJob() })();
        // eslint-disable-next-line
    }, [])


    useEffect(() => {
        jobData?.jobData.forEach((item) => {
            if (item.status === "Error") {
                setIsAnyMailFailed(true)
            }
            if (item.status === "Sent") {
                setEmailCount((prev) => ({ ...prev, sent: prev.sent + 1 }))
            }
            if (item.status === "Error") {
                setEmailCount((prev) => ({ ...prev, error: prev.error + 1 }))
            }
        })

        if (jobData?.jobStatus === "Completed") {
            getBulkTemplates()
        }
        // eslint-disable-next-line
    }, [jobData])

    useEffect(() => {
        if (jobData?.jobStatus === "Completed") {
            let template = bulkTemplates.find((item) => item._id === jobData.templateId)
            setSelectedTemplate(template)
        }
        // eslint-disable-next-line
    }, [bulkTemplates])

    const getSingleJob = async () => {
        try {
            if (emailJobId) {
                let response = await getSingleEmailJob(emailJobId)
                setJobData(response.data)
            } else {
                alert("Invalid JobId")
                navigate("/bulk_email")
            }
        } catch (error: any) {
            console.log(error)
            if (error?.response?.data?.statusCode === 404 || 400) {
                alert("Invalid JobId")
            } else {
                alert(error?.response?.data?.message)
            }
            navigate("/bulk_email")
        }
    }

    const getBulkTemplates = async () => {
        if (bulkTemplates.length <= 0) {
            try {
                let response = await getBulkEmailTemplates()
                setBulkTemplates(response.data)
            } catch (error) {
                console.log(error)
                alert("Error while getting Templates")
            }
        } else {
            return
        }
    }

    const handleTemplateInput = (e: React.ChangeEvent<HTMLSelectElement>) => {
        let temp = bulkTemplates.filter((item) => item._id === e.target.value)
        setSelectedTemplate(temp[0])
    }

    const handleFilterModal = () => {
        setBenFilter(!benFilter);
    };

    const deleteJobBen = async (benId: string) => {
        try {
            let existingJobData = jobData
            if (existingJobData) {
                let filteredEmails = existingJobData.jobData.filter((item) => item.benId !== benId)
                existingJobData.jobData = filteredEmails
                let response = await updateBenToJob(existingJobData._id, existingJobData.jobData)
                setJobData(response)
            }
        } catch (error: any) {
            alert(error?.response?.data?.message)
        }
    }

    const sendEmail = async (e: React.MouseEvent<HTMLButtonElement>) => {
        setIsLoading(true)
        const emailTo = (e.currentTarget as HTMLButtonElement).value;
        try {
            if (selectedTemplate && emailJobId) {
                if (selectedTemplate.variables.length > 0) {
                    if (Object.keys(inputVariables).length !== selectedTemplate.variables.length) {
                        alert("Please provide input variable data")
                        setIsLoading(false)
                        return
                    } else {
                        for (let key in inputVariables) {
                            if (!selectedTemplate.variables.includes(key) || !inputVariables[key]) {
                                alert("Please provide input variable data")
                                setIsLoading(false)
                                return
                            }
                        }
                    }
                }

                await sendBulkEmail(selectedTemplate._id, emailJobId, { emailVars: inputVariables, emailTo: emailTo })
                toast({
                    title: 'Email Sent.',
                    description: "Please Check status of the email.",
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                    position: "top"
                })
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            } else {
                alert("Please Select Email Template")
            }
            setIsLoading(false)
        } catch (error: any) {
            alert(error?.response?.data?.message)
            setIsLoading(false)
            toast({
                title: 'Request Failed',
                description: "Please try again.",
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: "top"
            })
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        }

    }


    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputVariables((prev: object) => { return { ...prev, [e.target.placeholder]: e.target.value } })
    }

    const clearJobData = async () => {
        if (jobData) {
            let response = await updateBenToJob(jobData._id, [])
            console.log(response)
            setJobData(response)
        }
    }

    return (
        <Layout>
            <Box width={"95%"} margin={"auto"} >
                <Flex width={"fit-content"} padding={"10px"} borderRadius={"10px"} gap={"20px"}>
                    <Text color={"blue"} fontWeight={"bold"}>Total Email: {jobData ? jobData.jobData.length : "0"}</Text>
                    <Text color={"green"} fontWeight={"bold"}>Sent Email: {emailCount.sent}</Text>
                    <Text color={"red"} fontWeight={"bold"}>Failed Email: {emailCount.error}</Text>
                </Flex>
                <Flex gap={"20px"} mt={"30px"} justifyContent={"center"}>
                    {jobData?.jobStatus !== "Completed" && <Select placeholder="Select Template" onClick={getBulkTemplates} width={"fit-content"} onChange={handleTemplateInput} isDisabled={jobData?.jobStatus === "Completed"}>
                        {bulkTemplates.length > 0 && bulkTemplates.map((item) =>
                            <option value={item._id} key={item._id}> {item.name}</option>
                        )}
                    </Select>}
                    {jobData?.jobStatus === "Completed" && selectedTemplate && <Select width={"fit-content"} onChange={handleTemplateInput} isDisabled={jobData?.jobStatus === "Completed"}>
                        <option value={selectedTemplate._id} key={selectedTemplate._id}> {selectedTemplate.name}</option>
                    </Select>}
                    <Flex gap={"20px"}>
                        <Button colorScheme="green" value={"All"} onClick={sendEmail} isDisabled={jobData?.jobStatus === "Completed" || jobData?.jobStatus === "New" || isLoading || !selectedTemplate}>Send Email</Button>
                        <Button colorScheme="green" onClick={handleFilterModal} isDisabled={jobData?.jobStatus === "Completed" || isLoading}>Add Beneficiary</Button>
                        <Button colorScheme="green" value={"Subscriber"} isDisabled={jobData?.jobStatus === "Completed" || jobData?.jobStatus === "New" || isLoading || !selectedTemplate} onClick={sendEmail}>Send To Subscriber</Button>
                        <Button colorScheme="green" onClick={sendEmail} display={isAnyMailFailed && jobData?.jobStatus === "Completed" ? "block" : "none"} isDisabled={isLoading}>Resend Failed</Button>
                        <Button colorScheme="green" onClick={clearJobData} isDisabled={jobData?.jobStatus === "Completed" || jobData?.jobStatus === "New" || isLoading}>Clear Ben</Button>
                    </Flex>
                </Flex>

                {selectedTemplate && selectedTemplate?.variables?.length > 0 && ((jobData?.jobStatus !== "Completed") || (jobData.jobStatus === "Completed" && isAnyMailFailed)) &&
                    <Flex mt={"20px"} justifyContent={"center"}
                        maxHeight={isVisible ? '200px' : '0'}
                        opacity={isVisible ? 1 : 0}
                        overflow="hidden"
                        transition="max-height 0.3s ease, opacity 0.3s ease"
                    >
                        {selectedTemplate.variables.map((item) =>
                            <Input placeholder={item} maxWidth={"50%"} margin={"10px"} onChange={handleInput}></Input>
                        )}
                    </Flex>
                }
                <Table variant="unstyled" mt={"30px"}>
                    <Thead>
                        <Tr>
                            <Th>SR.NO</Th>
                            <Th>BENEFICIARY NAME</Th>
                            <Th>BENEFICIARY EMAIL</Th>
                            <Th>BENEFICIARY ID</Th>
                            <Th>EMAIL STATUS</Th>
                            <Th>Remove</Th>
                        </Tr>
                    </Thead>
                    <Tbody >
                        {jobData &&
                            jobData.jobData.map((item, index) => {
                                return (
                                    <Tr>
                                        <Td>{index + 1}</Td>
                                        <Td>{item.benName}</Td>
                                        <Td>{item.benEmail}</Td>
                                        <Td>{item.benId}</Td>
                                        <Td>{item.status}</Td>
                                        <Td>
                                            <Flex justifyContent={"center"} >
                                                <Button onClick={() => deleteJobBen(item.benId)} isDisabled={jobData.jobStatus === "Completed"}>
                                                    <MdDelete color="red" ></MdDelete>
                                                </Button>
                                            </Flex>
                                        </Td>
                                    </Tr>
                                )
                            })
                        }
                    </Tbody>
                </Table>
                {benFilter && <BenFilterPopup handleCloseModal={() => handleCloseModal(setBenFilter, benFilter)}></BenFilterPopup>}
            </Box>

        </Layout >
    )
}