import { Box, Button, Heading, Input, Spinner } from "@chakra-ui/react"
import { UploadUserExcel } from "../UploadUserExcel/UploadUserExcel"
import {
    ChakraProvider,
    FormControl,
    FormLabel,
    Checkbox,
    CheckboxGroup,
    extendTheme,

} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { searchQueryFun } from "./ManageUserFun";
import { UserDataTable } from "./userDataTable";
import { UpdateUser } from "../UploadUserExcel/UpdateUser";
import DeleteUsersInBulk from "../DeleteUsersInBulk/DeleteUsersInBulk";


const activeLabelStyles = {
    transform: "scale(0.85) translateY(-24px)"
};
const theme = extendTheme({
    components: {
        Form: {
            variants: {
                floating: {
                    container: {
                        _focusWithin: {
                            label: {
                                ...activeLabelStyles
                            }
                        },
                        "input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label": {
                            ...activeLabelStyles
                        },
                        label: {
                            top: 0,
                            left: 0,
                            zIndex: 2,
                            position: "absolute",
                            backgroundColor: "white",
                            pointerEvents: "none",
                            mx: 3,
                            px: 1,
                            my: 2,
                            transformOrigin: "left top"
                        }
                    }
                }
            }
        }
    }
});

interface initialPayloadInterface {
    collection: string,
    ben_id: string,
    email: string,
    name: string,
    mobile: string,
}

const initialPayload: initialPayloadInterface = {
    collection: "",
    ben_id: "",
    email: "",
    name: "",
    mobile: "",
}

export const ManageUserComponent = () => {
    const [inputPayload, setInputPayload] = useState<initialPayloadInterface>(initialPayload)
    const [isLoading, setIsLoading] = useState<Boolean>(false)
    const [userData, setUserData] = useState<Object[]>([])
    const [localUserData, setLocalUserData] = useState<Object[]>([])
    const [page, setPage] = useState<number>(1)
    const [limit, setLimit] = useState<number>(10)
    const [totalPages, setTotalPages] = useState<number>(0)
    const searchRef = useRef(null)

    useEffect(() => {
        setTotalPages(Math.ceil(userData.length / limit))
        const startIndex = (page - 1) * limit;
        const endIndex = startIndex + limit;
        const filteredData = userData.slice(startIndex, endIndex);
        setLocalUserData(filteredData)
    }, [userData, page, limit])


    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputPayload({ ...inputPayload, [e.target.name]: e.target.value })
    }

    const handleCheckbox = (e: any) => {
        let collectionString = e.join("&")
        setInputPayload({ ...inputPayload, collection: collectionString })
    }

    const submitReq = async () => {
        setPage(1)
        setLimit(10)
        setUserData([])
        setIsLoading(true)
        let response = await searchQueryFun(inputPayload)
        response?.subResponse?.forEach((item: any) => {
            setUserData((prev) => [...prev, { ...item, collection: "Subscriber" }])
        })
        response?.benResponse?.forEach((item: any) => {
            setUserData((prev) => [...prev, { ...item, collection: "Beneficiary" }])
        })
        setIsLoading(false)
    }

    const handlePage = (pageNo: number) => {
        setPage((prev) => prev + pageNo)
    }

    const handleLimit = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setLimit(Number(e.target.value))
    }
    const clearFilters = () => {
        setInputPayload(initialPayload)
        setPage(1)
        setLimit(10)
    }
    return (
        <Box>
            <Box display={"flex"} justifyContent={"center"} mt={"10px"}>
                <Heading size={"lg"} >Manage User Data</Heading>
            </Box>

            <Box>
                <Box display={"flex"} justifyContent={"left"} mt={"20px"}>
                    <Heading size={"md"}>Add Multiple Users :</Heading>
                </Box>
                <Box mt={"20px"} >
                    <UploadUserExcel />
                </Box>
            </Box>
            <Box mt={"30px"}>
                <Box display={"flex"} justifyContent={"left"} mt={"20px"}>
                    <Heading size={"md"}>Update Users :</Heading>
                </Box>
                <Box mt={"20px"} >
                    <UpdateUser />
                </Box>
            </Box>
            {/* Delete Users in bulk uploading excel file  */}
            <Box mt={"30px"}>
                <Box display={"flex"} justifyContent={"left"} mt={"20px"}>
                    <Heading size={"md"}>Delete Users In Bulk :</Heading>
                </Box>
                <Box mt={"20px"} >
                    <DeleteUsersInBulk/>
                </Box>
            </Box>

            <Box mt={"30px"}>
                <Box display={"flex"} justifyContent={"left"} mt={"20px"}>
                    <Heading size={"md"}>Delete Users :</Heading>
                </Box>
                <Box mt={"10px"} display={"flex"} gap={"50px"} alignItems={"center"}>
                    <Box >
                        <CheckboxGroup onChange={handleCheckbox}>
                            <Checkbox display={"block"} value={"ben"} >Search By Beneficiary</Checkbox>
                            <Checkbox display={"block"} value={"sub"}>Search By Subscriber</Checkbox>
                        </CheckboxGroup>
                    </Box>
                    <Box>
                        <ChakraProvider theme={theme}>
                            <Box>
                                <FormControl variant="floating" >
                                    <Input placeholder=" " name="ben_id" onChange={handleInput} value={inputPayload.ben_id} />
                                    <FormLabel>Beneficiary Id</FormLabel>
                                </FormControl>
                            </Box>
                        </ChakraProvider>

                    </Box>
                    <Box>
                        <ChakraProvider theme={theme}>
                            <Box >
                                <FormControl variant="floating"  >
                                    <Input placeholder=" " name="email" onChange={handleInput} value={inputPayload.email} />
                                    <FormLabel>Email</FormLabel>
                                </FormControl>
                            </Box>
                        </ChakraProvider>
                    </Box>
                    <Box>
                        <ChakraProvider theme={theme}>
                            <Box >
                                <FormControl variant="floating" >
                                    <Input placeholder=" " name="name" onChange={handleInput} value={inputPayload.name} />
                                    <FormLabel>Name</FormLabel>
                                </FormControl>
                            </Box>
                        </ChakraProvider>
                    </Box>
                    <Box>
                        <ChakraProvider theme={theme}>
                            <Box >
                                <FormControl variant="floating" >
                                    <Input placeholder=" " name="mobile" onChange={handleInput} value={inputPayload.mobile} />
                                    <FormLabel>Phone Number</FormLabel>
                                </FormControl>
                            </Box>
                        </ChakraProvider>
                    </Box>
                    <Box>
                        <Button onClick={clearFilters} colorScheme="green">Clear Filters</Button>
                    </Box>
                </Box>
            </Box>
            <Box mt={"20px"} display={"flex"} justifyContent={"center"}>
                <Button padding={"20px"} width={"200px"} onClick={submitReq} ref={searchRef} colorScheme="green">Search</Button>
            </Box>
            {isLoading === false && <Box mt={"30px"}>
                {localUserData.length !== 0 && <UserDataTable data={localUserData} searchRef={searchRef} totalPages={totalPages} page={page} limit={limit} handlePage={handlePage} handleLimit={handleLimit} />}
            </Box>}
            {isLoading && <Spinner
                ml={"48%"}
                mt={"150px"}
                thickness='4px'
                speed='0.65s'
                emptyColor='gray.200'
                color='green.500'
                size='xl'
            />}
        </Box>
    )
}