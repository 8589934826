import React, {   } from "react";
// import { useNavigate } from "react-router-dom";

interface PrivateRouteProps {
  children: any;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
//   const navigate = useNavigate();
//   const token = localStorage.getItem("isLogin");

//   useEffect(() => {
//     if (!token || token !== "true") {
//       navigate("/login");
//     }
// }, [navigate, token]);
  
  return children;
};
