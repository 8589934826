import { getCompany, handleExcelSheet, updateUserByExcel } from "../../../Http/Index"


export const handleExcelSheetReq = async (payload: any) => {
    try {
        let res = await handleExcelSheet(payload)
        // console.log(res)
        return res
    } catch (error) {
        console.log(error)
        return error
    }
}


export const getCompanyList = async () => {
    try {
        const res = await getCompany()
        return res.data
    } catch (e) {
        console.log(e)
        throw e;
    }
}

export const updateUserFun = async (payload: any) => {
    try {
        let res = await updateUserByExcel(payload)
        // console.log(res)
        return res
    } catch (error) {
        console.log(error)
        return error
    }
}