import React from "react";


interface Company {
    _id: string;                     
    companyName: string;             
    subscriptionType: string;       
    dateOfPartnership: string;       
}

interface TableDataProps {
    data: Company[];                
}

const TableData: React.FC<TableDataProps> = ({ data }) => {
    return (
        <div style={{marginTop:"100px",marginRight:"20px",marginBottom:"100px"}}>
            <table>
                <thead>
                    <tr>
                        <th>Sl.No</th>
                        <th>Company Name</th>
                        <th>Subscription Type</th>
                        <th>Date of Partnership</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map(({ companyName, subscriptionType, dateOfPartnership }, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{companyName}</td>
                            <td>{subscriptionType}</td>
                            <td>{dateOfPartnership}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default TableData;
