import { getCompany } from "../../../Http/Index";

export const getCompanyList=async()=>{
    try{
        const res=await getCompany()
        return res.data
    }catch(e){
        throw e;
    }

}