// MicrosoftLogin Login.js
import { Box, Button, Flex, Image } from "@chakra-ui/react";
import apiConfig from "../../ApiConfig/apiConfig";
const baseUri = apiConfig.api.baseURL;

const MicrosoftLogin = () => {
  let  url = `${baseUri}/auth/api/v1/auth/ops/microsoft`;
  const handleMicrosoftLogin = () => {
    window.location.href = url;
  };

  return (
    <Box >
      <Button
        color="white"
        bg="rgb(213,63,140)"
        width="100%"
        type="submit"
        id="login-button"
        onClick={handleMicrosoftLogin}

      >
        <Flex alignItems="center" justifyContent="center">
            <span >Login with Microsoft </span>
          <Image
            src="https://developmentbucket.blob.core.windows.net/logos/Microsoft_logo.png"
            alt="Microsoft Logo"
            width="6%"
            ml={"5px"}
          />
        </Flex>
      </Button>
    </Box>
  );
};

export default MicrosoftLogin;
