import { ShowAlert } from "../../../GlobalComponent/Alerts/Alert";
import { downloadBenDashBoardExcel, updateWaJobById } from "../../../Http/Index";
import { getJobByJobId } from "../../WaMessageDetails/Action/action";


export const handleUpdateWaDoc=async ({data,jobId,navigate}:any)=>{
  const getALreadyPresentData=await getJobByJobId(jobId);
  // console.log('getALreadyPresentData:', getALreadyPresentData.jobData,getALreadyPresentData);
 const payload=buildPayload(data);
 const combinedData=mergeData({oldData:getALreadyPresentData?.jobData,newData:payload});
//  console.log('combinedData:', combinedData);
 const formatePayload={
   jobData:combinedData
 }
 
 try{
   await updateWaJobById(jobId,formatePayload);
   ShowAlert("success", "Beneficiaries Added Successfully");
  window.location.reload();
 }catch(error){
   ShowAlert("warning", "Something went wrong");
 }
}

const buildPayload=(data:any)=>{
    const payload=data.map((benFields: any) => {
      // console.log('benFields:', benFields);
       if(!benFields.isDeleted){
        return{
           benId:benFields._id || benFields?.benId,
           phoneNumber:benFields?.personalInfo?.whatsappMobile?.number||benFields?.personalInfo?.mobile?.number||benFields?.benWhatsappMobile||benFields?.benMobile,
        };
      }else{
        return null
      }
    }).filter((el:any)=>el!==null);
    // console.log('payload:#####################', payload);
    return payload;
}

const mergeData=({oldData,newData}:any)=>{
 let combinedData=[...oldData,...newData];
//  console.log('combinedData:', combinedData);
 const mergedData = combinedData.reduce((result, obj) => {
  // console.log('result:', result)
  const existingObject = result.find((item:any) => item.benId === obj.benId);

  if (existingObject) {
    // Merge if the benId already exists
    Object.assign(existingObject, obj);
  } else {
    // Otherwise, add a new object
    result.push({ ...obj });
  }

  return result;
}, []);
return mergedData;  
}

export const handleCloseModal = (setBenFilter:any,benFilter:any) => {
   setBenFilter(!benFilter);  
 };

 export const downloadBenDashBoardData=async()=>{
  try{
    const response=await downloadBenDashBoardExcel();
    // console.log('response:', response);
     await makeDownload(response);
  }catch(e:any){
    console.log(e);
    ShowAlert("warning", e?.response?.data?.message || "Downloading Excel Failed");
  }
 }

 export const makeDownload=async(response:any)=>{
  
    const url = window.URL.createObjectURL(response.data);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'benCallingList.xlsx'; 
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
 }