import style from "./Card.module.css";

interface CardProps {
  data?: any;
}

export const CustomCard: React.FC<CardProps> = ({ data }) => {
  // console.log('data:', data)
  return (
    <>
    <div className={style.card_Wrapper}>
      <div className={style.flex}>
        {data && Object.entries(data)?.map(([key, value]:any) => (
          <div key={key}>
            <p className={style.card_heading}>{key}: {value}</p>
          </div>
        ))}
      </div>
    </div>
  </>
  );
};
