import { useEffect, useState } from "react";
import { getReqFieldFromObj } from "../Action/action";
import { CustomCard } from "../../../GlobalComponent/Card/Card";
import style from "./cardWrapper.module.css";
interface CardWrapperProps {
  data: any;
}
export const CardWrapper: React.FC<CardWrapperProps> = ({ data }) => {
  const [overAllCount, setOverAllCount] = useState<any>();
  const [activateCount, setActivateCount] = useState<any>();
  const [qrCount, setQrCount] = useState<any>();
  const [validNumber, setValidNumber] = useState<any>();
  // console.log("data:", data);
  useEffect(() => {
    const mandatoryFieldForCount = ["totalSubscriber", "totalBeneficiary"];
    const mandatoryFieldFValidNum = ["validNumber", "notValidNumber"];
    const mandatoryQrCount = ["qrDispatched", "qrNotDispatched"];
    const getAllCount = getReqFieldFromObj(data, mandatoryFieldForCount);
    setOverAllCount(getAllCount);
    const mandatoryFieldForActivateCount = [
      "activatedBeneficiary",
      "notActivatedBeneficiary",
    ];
    const getActivateCount = getReqFieldFromObj(
      data,
      mandatoryFieldForActivateCount
    );
    setActivateCount(getActivateCount);
    const getValidCount = getReqFieldFromObj(data, mandatoryFieldFValidNum);
    setValidNumber(getValidCount);
    const getQrCount = getReqFieldFromObj(data, mandatoryQrCount);
    setQrCount(getQrCount);
  }, [data]);

  return (
    <div>
      <div className={style.flex}>
        <CustomCard data={overAllCount} />
        <CustomCard data={activateCount} />
        <CustomCard data={validNumber} />
        <CustomCard data={qrCount} />
      </div>
    </div>
  );
};
