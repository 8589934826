import {  Modal, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from "@chakra-ui/react"
import BenAddressData from "../../Pages/BenAddressData/BenAddressData"

interface BenFilterProps{
    handleCloseModal:any
}
export const BenFilterPopup:React.FC<BenFilterProps>=({handleCloseModal})=>{
    // const { isOpen, onOpen, onClose } = useDisclosure();
    return(
        <>
        <Modal size={"xxl"} isOpen={true} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent padding={"1rem"}>
        <ModalCloseButton />
            <ModalHeader>BenFilter</ModalHeader>           
            <BenAddressData/>     
            {/* <ModalFooter gap={"1rem"}></ModalFooter> */}
        </ModalContent>
        </Modal>
        </>
    )
}