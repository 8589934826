
import style from "./checkbox.module.css";
interface CustomCheckBoxProps {
    label: any,
    checked: any,
    onChange: any

}

const emptyDetailsNames: any = {
    noAddress: "No Address",
    noEmContact: "No Emergency Contacts",
    noInsurance: "No Insurance Polices",
    noMedicalDet: "No MedicalInfo",
    noPersonalInfo: "No PersonalInfo",
    beneficiary: "Search By Beneficiary",
    subscriber: "Search By Subscriber",
    noPreferredHospital: "No Preferred Hospital",
    isDeleted: "is Deleted",
    callingList: "Calling List",
    activatedBen: "activated Ben",
    notActivatedBen: "notActivated Ben",
    sameAsSubscriberNumber: "sameAsSubscriberNumber",
    notSameAsSubscriberNumber: "notSameAsSubscriberNumber",
    qrDispatched: "QR Dispatched",
    qrNotDispatched: "QR Not Dispatched"
}
export const CustomeCheckBox: React.FC<CustomCheckBoxProps> = ({ label, checked, onChange }) => {
    // console.log('checked:', label);
    return (
        <>
            <div className={style.wrapper}>
                <label>
                    <input className={style.inputStyle} type="checkbox" checked={checked} onChange={() => onChange(label)} />
                    {emptyDetailsNames[label[0]] ||label}
                </label>
            </div>
        </>
    )
}