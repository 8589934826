import { Box, Button, Flex, Input, Link } from "@chakra-ui/react";
import React, { useState } from "react";
import { Spinner } from '@chakra-ui/react';
import * as XLSX from 'xlsx';
import ExcelJS from "exceljs"
import { updateUserFun } from "./Action/uploadUserExcelFun";
import { FaDownload } from "react-icons/fa";
import style from "./UploadUserExcel.module.css";

export const UpdateUser: React.FC = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [excelState, setExcelState] = useState<any>(null)

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        setExcelState(e.target?.files?.[0])
    }

    const handleUpdateUser = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        if (!excelState) {
            alert("Please Choose excel file")
            return
        }

        const reader = new FileReader();
        reader.onload = (event) => {
            if (event.target) {
                const data = new Uint8Array(event.target.result as ArrayBuffer);
                const workbook = XLSX.read(data, { type: 'array' });
                const sheet = workbook.Sheets["updateUser"];
                const parsedData = XLSX.utils.sheet_to_json(sheet, { header: 1, defval: "" }) as string[][];
                performAction(parsedData)
            }
        };
        reader.readAsArrayBuffer(excelState);
    }

    const performAction = async (parsedData: string[][]) => {

        if (parsedData.length === 0) {
            alert("Sheet is empty or sheet name might be different")
            return
        }

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('updateUser')
        worksheet.addRow(parsedData[0])
        worksheet.addRow(parsedData[1])

        let excelFormatStatus = checkExcelFormat(parsedData[1])

        if (excelFormatStatus === false) {
            alert("Please upload excel in defined format")
            return
        }

        setIsLoading(true)
        for (let i = 2; i < parsedData.length; i++) {

            if (parsedData[i].length === 0) {
                continue
            }

            let row = worksheet.addRow(parsedData[i])

            let benId = parsedData[i][1]?.toString()?.trim();
            let benName = parsedData[i][2]?.toString()?.trim();
            let benEmail = parsedData[i][3]?.toString()?.toLowerCase()?.trim();
            let subscriberEmail = parsedData[i][4]?.toString()?.toLowerCase()?.trim();
            let addressLine1 = parsedData[i][5]?.toString()?.trim();
            let addressLine2 = parsedData[i][6]?.toString()?.trim();
            let landmark = parsedData[i][7]?.toString()?.trim();
            let isLiftAvailable = parsedData[i][8];
            let isReachableByAmbulance = parsedData[i][9];
            let city = parsedData[i][10]?.toString()?.trim();
            let state = parsedData[i][11]?.toString()?.trim()
            let pinCode = parsedData[i][12];
            let locationLatitude = parsedData[i][13]?.toString()?.trim();
            let locationLongitude = parsedData[i][14]?.toString()?.trim();
            let typeOfPolicy = parsedData[i][15]?.toString()?.trim();
            let insurancePolicyId = parsedData[i][16]?.toString()?.trim();
            let nameOfInsurer = parsedData[i][17]?.toString()?.trim();
            let tpaName = parsedData[i][18]?.toString()?.trim();
            let country = "India"

            if (typeOfPolicy) {
                if (typeOfPolicy === "esic" || typeOfPolicy === "ESIC") {
                    let newTypeOfPolicy = typeOfPolicy.toUpperCase()
                    typeOfPolicy = newTypeOfPolicy
                } else {
                    let newTypeOfPolicy = typeOfPolicy.charAt(0).toUpperCase() + typeOfPolicy.slice(1).toLowerCase()
                    typeOfPolicy = newTypeOfPolicy
                }
            }

            let subscriberData: any = {
                benId,
                benName,
                benEmail,
                subscriberEmail,
                addressLine1,
                addressLine2,
                landmark,
                isLiftAvailable,
                isReachableByAmbulance,
                city,
                state,
                pinCode,
                locationLatitude,
                locationLongitude,
                typeOfPolicy,
                insurancePolicyId,
                nameOfInsurer,
                tpaName,
                country
            }

            for (let key in subscriberData) {
                if (subscriberData[key] === "" || subscriberData[key] === undefined || subscriberData[key] === null) {
                    delete subscriberData[key]
                }
            }
            // console.log("subscriberData", subscriberData)

            let inputValidationFlag = inputValidation(subscriberData)

            if (inputValidationFlag) {
                row.getCell(20).value = inputValidationFlag
                row.getCell(20).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFF0000' }
                };

            } else {
                let response: any = await updateUserFun(subscriberData)
                // console.log("response", response)
                if (response.status === 201) {
                    row.getCell(20).value = response.data
                    row.getCell(20).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FF00FF00' }
                    };
                } else {
                    row.getCell(20).value = response.response?.data?.message
                    row.getCell(20).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFF0000' }
                    };
                }
            }


        }
        worksheet.columns.forEach((column) => column.width = 20)
        worksheet.eachRow((row) => {
            row.eachCell((cell) => {
                cell.alignment = { horizontal: 'left' };
                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                };
            });
        });

        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        const url = window.URL.createObjectURL(blob);


        const a = document.createElement('a');
        a.href = url;
        a.download = 'update_user_modified_excel.xlsx';


        document.body.appendChild(a);
        a.click();


        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        setIsLoading(false)
    }

    function inputValidation(data: any) {
        let validationTxt = '';

        if (data.pinCode) {
            if (!Number(data.pinCode)) {
                validationTxt += 'pincode should be number, '
            }
        }

        if (data.pinCode) {
            if (data.pinCode > 999999 || data.pinCode < 100000) {
                validationTxt += 'pincode should be 6 digit, '
            }
        }

        if (!data.benId) {
            if (!data.benEmail) {
                validationTxt += 'provide either benId or BenEmail, '
            }
        }

        if (data.isReachableByAmbulance) {
            if (typeof data.isReachableByAmbulance !== "boolean") {
                validationTxt += 'isReachableByAmbulance should be TRUE or FALSE, '
            }
        }

        if (data.isLiftAvailable) {
            if (typeof data.isLiftAvailable !== "boolean") {
                validationTxt += 'isLiftAvailable should be TRUE or FALSE, '
            }
        }

        if (!data.benName) {
            validationTxt += 'provide Beneficiary Name, '
        }

        if (data.email) {
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            if (!data.email.match(emailRegex)) {
                validationTxt += 'Email Should be valid, '
            }
        }


        if (!data.subscriberEmail) {
            validationTxt += 'Subscriber email should be defined, '
        }

        if (data.subscriberEmail) {
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            if (!data.subscriberEmail.match(emailRegex)) {
                validationTxt += 'Subscriber Email Should be valid, '
            }
        }

        if (data.insurancePolicyId) {
            if (!data.typeOfPolicy) {
                validationTxt += 'type of policy should be valid, '
            }
        }

        return validationTxt
    }

    const checkExcelFormat = (data: String[]) => {

        if (data[0] !== "Sl. No.") {
            return false
        } else if (data[1] !== "Ben Id") {
            return false
        } else if (data[2] !== "Ben Name") {
            return false
        } else if (data[3] !== "Ben Email") {
            return false
        } else if (data[4] !== "Subscriber Email") {
            return false
        } else if (data[5] !== "addressLine1") {
            return false
        } else if (data[6] !== "addressLine2") {
            return false
        } else if (data[7] !== "Landmark") {
            return false
        } else if (data[8] !== "isLiftAvailable") {
            return false
        } else if (data[9] !== "isReachableByAmbulance") {
            return false
        } else if (data[10] !== "City") {
            return false
        } else if (data[11] !== "State") {
            return false
        } else if (data[12] !== "Pincode") {
            return false
        } else if (data[13] !== "Latitude") {
            return false
        } else if (data[14] !== "Longitude") {
            return false
        } else if (data[15] !== "Policy Type") {
            return false
        } else if (data[16] !== "InsurancePolicyId") {
            return false
        } else if (data[17] !== "Insurer") {
            return false
        } else if (data[18] !== "TPA Name") {
            return false
        }
    }

    // const handleClearFilter = () => {
    //     window.location.reload()
    // }

    return (
        <Box alignItems={"center"}>
            <form className={style.excelForm} onSubmit={handleUpdateUser} encType="multipart/form-data">
                <Button type="submit" padding={"20px"} width={"200px"} colorScheme="green" isDisabled={isLoading}>
                    {isLoading === false && "Update Users"}
                    {isLoading && <Spinner
                        thickness='4px'
                        speed='0.65s'
                        emptyColor='gray.200'
                        color='blue.500'
                        size='md'
                    />}
                </Button>
                <Input type="file" onChange={handleInput} width={"250px"} accept=".xls,.xlsx,application/vnd.ms-excel" />
                <Link href="https://pocostorage.blob.core.windows.net/others/UpdateUser%20Template%20v2.xlsx"><Flex alignItems={"center"} gap={"10px"}><FaDownload color="green" /> Update Template</Flex></Link>
                {/* <Button colorScheme="green" onClick={handleClearFilter}>Clear Filters</Button> */}
            </form>
        </Box>
    )
}