import { getSingleEmailJob, updateBulkEmailJob } from "../../../Http/Index"


export const addBenToEmailJob = async (id: string, data: any) => {
    try {
        let existingJobData = await getSingleEmailJob(id)

        const validateEmail = (email: string): boolean => {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        };

        data.forEach((ben: any) => {
            if (ben.isDeleted === false) {
                if (validateEmail(ben.email || ben.personalInfo.email)) {
                    let isBenPresent = false
                    for (let item of existingJobData.data.jobData) {
                        if (item.benId === ben.benId || item.benId === ben._id) {
                            isBenPresent = true
                            break;
                        }
                    }
                    if (isBenPresent === false) {
                        existingJobData.data.jobData.push({
                            benId: ben.benId || ben._id,
                            subId: ben.subscriberId || ben.subscriberId,
                            benEmail: ben.email || ben.personalInfo.email,
                            benName: ben.benName || ben.personalInfo.fullName
                        })
                    }
                }
            }
        })

        let payload = { jobData: existingJobData.data.jobData }
        await updateBulkEmailJob(id, payload)
        window.location.reload();
    } catch (error: any) {
        alert(error?.response?.data?.message)
    }
}