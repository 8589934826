import React from "react";
import style from "./CustomSelect.module.css"

interface CustomSelectProps {
  optionList: any[];
  setCompanySelected: any;
  getOptionList:any;
  label:string
 
}
export const CustomSelect: React.FC<CustomSelectProps> = ({
  label,
  optionList,
  setCompanySelected,
  getOptionList
  
}) => {
  const handleCatchValue = (value: string) => {
    console.log('value:', value,`${label}`)
    if(value!==`${label}`){
      setCompanySelected(value);
    }else{
      // alert("dfdf");
      setCompanySelected("");
    }
  };
  const handleGetList=()=>{
    getOptionList();
  }
  

//   console.log("templateList:", templateList);
  return (
    <>
      <div>
        <select
        onClick={handleGetList}
        className={style.select_border}
          placeholder={`Select ${label}`}
          onChange={(e) => handleCatchValue(e.target.value)}
          disabled={false}
        >
          <option>{label}</option>
          {optionList &&
            Object.keys(optionList)?.map(
              (optionName: string, index: number) => {
                return (
                  <React.Fragment key={optionName}>
                    <option value={optionName}>{optionName}</option>
                  </React.Fragment>
                );
              }
            )}
        </select>
      </div>
    </>
  );
};
