import { ShowAlert } from "../../../GlobalComponent/Alerts/Alert";
import { whatsappMsgDeliveryStatus } from "../../../Http/Index";

export const findIncompleteJob = ({ waJobData, jobId, navigate }: any) => {
  if (waJobData?.jobData?.length) {
     // eslint-disable-next-line
    waJobData?.jobData?.map((job: any) => {
      if (job.whatsappMsgId !== "error failed") {
        return navigate(`/bulk_wa_message/${jobId}`);
      }
    });
  } else {
    return navigate(`/bulk_wa_message/${jobId}`);
  }
};
const buildTablePayload=({jobData,waJobData}:any)=>{
  // console.log('jobData:', jobData,waJobData)
  return waJobData.map((job:any,index:number)=>{
    let findMatchedWaJob=jobData.find((waJob:any)=>waJob.whatsappMsgId===job.whatsappMsgId);
    // console.log('findMatchedWaJob:', job,findMatchedWaJob,job.whatsappMsgId);
    //error wa message we don't have any id that why i used or condition with failed
    return {sno:index+1,...job,deliveryStatus:findMatchedWaJob?.deliveryStatus||"Failed"}
  })

}
export const getDeliveryStatus = async ({
  waJobData,
  jobId,
  navigate,
}: any) => {
  let responseStatus: any = [];
  let validJobDate:any=[];
  let statusChecker=false;
  if (waJobData?.jobData?.length) {
    const buildTableData=await waJobData.jobData.map(async (job: any) => {
      console.log('job:', job);
            //  console.log('job:', job)
            if (job.whatsappMsgId === "error failed") {
        //         ShowAlert(
        //   "warning",
        //   "Some of the Message are not Send to beneficiaries"
        // );
        // return navigate(`/bulk_wa_message/${jobId}`);
        validJobDate.push(job);
        statusChecker=true;
        responseStatus.push({deliveryStatus:"Failed",whatsappMsgId:"123" });
      }
      else if(job.whatsappMsgId){
        statusChecker=true;
        validJobDate.push(job);
        const res=await getDeliveryStatusByWaResId({waMsgId:job.whatsappMsgId,jobData:job});
        // console.log('res:', res.data.outbound_messages[0].status);
      // console.log("check",{...job,deliveryStatus:res.data.outbound_messages[0].status })
        responseStatus.push({deliveryStatus:res.data.outbound_messages[0].status,whatsappMsgId:res.data.outbound_messages[0].request_id });
      }
    });
    if(!statusChecker){
      ShowAlert("warning", "No Job Found");
     return navigate(`/bulk_wa_message/${jobId}`);
    }
   await Promise.all(buildTableData);   
   const combinedData=buildTablePayload({waJobData:validJobDate,jobData:responseStatus});
  //  console.log('combinedData:', combinedData);
    const deliveryCount=findDeliveryCount(combinedData);
   return {combinedData,deliveryCount};

  } else {
    ShowAlert("warning", "No Job Found");
    return navigate(`/bulk_wa_message/${jobId}`);
  }
  // console.log('responseStatus:', responseStatus)
};

export const findDeliveryCount=(tableData:any)=>{

  let uniqueData:any={};

  tableData.forEach((data:any)=>{
    if(!uniqueData[data.deliveryStatus]){
      uniqueData[data.deliveryStatus]=1
    }
    else{
      uniqueData[data.deliveryStatus]=uniqueData[data.deliveryStatus]+1
    }
  })
  Object.entries(uniqueData).forEach(([key, value]) => {
    uniqueData["total"]=(uniqueData["total"]||0)+value;
  })
return uniqueData;
}



export const getDeliveryStatusByWaResId = async ({ waMsgId,jobData }: any) => {
  // console.log('waMsgId:', waMsgId,jobData);  
  try {
    const res = await whatsappMsgDeliveryStatus(waMsgId);
    // console.log('res:', res);
    return res.data;
  } catch (e) {
    throw e;
  }
};
