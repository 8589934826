import { useCallback, useState } from "react";


export const UseCustomHook = (initialValue:any ) => {
  const [catchInput, setInput] = useState<any>(initialValue);

  const updateInput = useCallback((newValue: any) => {
    setInput(newValue);
  }, []);

  return {
    catchInput,updateInput
  };
};
