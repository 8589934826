import React, { useEffect, useState } from "react";
import { Layout } from "../../GlobalComponent/Layout/Layout";
import style from './AddCompanies.module.css';
import TableData from "./components/table";
import AddData from "./components/AddData";
import { getCompany as fetchCompany } from "../../Http/Index";
import { Box,  Heading } from "@chakra-ui/react"



interface Company {
  _id: string;                    
  companyName: string;             
  subscriptionType: string;        
  dateOfPartnership: string;       

}

const AddCompany = () => {
  const [companies, setCompanies] = useState<Company[]>([]);
 

  useEffect(() => {
    const getCompanyList = async () => {
      try {
        // Fetch the company data
        const response = await fetchCompany();
        
       
        const data= response.data; 
     
        setCompanies(data.data); 
      } catch (error) {
        console.error('Error fetching company data:', error);
      }
    };
    getCompanyList();
  }, []);

 


  return (
    <Layout>
      <div className={style.main_div}>
          <Box display={"flex"} justifyContent={"center"} mt={"10px"}>
                <Heading size={"lg"} >Manage Company</Heading>
            </Box>
         
            <AddData/>
            <TableData data={companies} /> 
      </div>
    </Layout>
  );
};

export default AddCompany;
