import { getDashBoardData } from "../../../Http/Index";

export const getDailyBenData = async (tag: any, from: any, to: any) => {
  try {
    const res = await getDashBoardData(tag, from, to);
    // console.log("res:", res.data);
    return res.data;
  } catch (e) {
    throw e;
  }
};

export const getReqFieldFromObj = (obj: any, mandatoryField: any) => {
  let reqField: any = {};

  for (let key in obj) {
    if (mandatoryField.includes(key)) {
      reqField[key] = obj[key];
    }
  }
  // console.log("reqField:", reqField);
  return reqField;
};

export const tableSeparator = (data: any) => {
  // console.log(data)

  const getDetails = data?.relationship;
  // console.log("getDetails:", getDetails);
  let tableData: any = [];
  let total: any = {
    "S.No": 0,
    relationship: "-",
    totalUser: 0,
    activatedUser: 0,
    notActivatedUser: 0,
    address: 0,
    basicDetails: 0,
    emergencyContact: 0,
    insurancePolicies: 0,
    medicalInfo: 0,
    preferredHospital: 0,
    validNumber:0,
    notValidNumber:0,
    qrDispatched:0,
    qrNotDispatched:0
  };
  if (getDetails) {
    for (let i = 0; i < getDetails.length; i++) {
      let obj: any = {};
      const key: any = Object.keys(getDetails[i]);
      obj["S.No"] = i + 1;
      obj["relationship"] = key==="Self"?"Employee": key.join(",");
      obj["totalUser"] =
        getDetails[i][key]["activatedBeneficiary"] +
        getDetails[i][key]["notActivatedBeneficiary"];
      obj["activatedUser"] = getDetails[i][key]["activatedBeneficiary"];
      obj["notActivatedUser"] = getDetails[i][key]["notActivatedBeneficiary"];
      obj["address"] = getDetails[i][key]["address"];
      obj["basicDetails"] = getDetails[i][key]["basicDetails"];
      obj["emergencyContact"] = getDetails[i][key]["emergencyContact"];
      obj["insurancePolicies"] = getDetails[i][key]["insurancePolicies"];
      obj["medicalInfo"] = getDetails[i][key]["medicalInfo"];
      obj["preferredHospital"] = getDetails[i][key]["preferredHospital"];
      obj["qrDispatched"] = getDetails[i][key]["qrDispatched"];
      obj["qrNotDispatched"] = getDetails[i][key]["qrNotDispatched"];
      obj["validNumber"] = getDetails[i][key]["validNumber"];
      obj["notValidNumber"] = getDetails[i][key]["notValidNumber"];
      tableData.push(obj);
      total["S.No"]="--";
      total["totalUser"] += obj["totalUser"];
      total["activatedUser"] += obj["activatedUser"];
      total["notActivatedUser"] += obj["notActivatedUser"];
      total["address"] += obj["address"];
      total["basicDetails"] += obj["basicDetails"];
      total["emergencyContact"] += obj["emergencyContact"];
      total["insurancePolicies"] += obj["insurancePolicies"];
      total["medicalInfo"] += obj["medicalInfo"];
      total["preferredHospital"] += obj["preferredHospital"];
      total["qrDispatched"] += obj["qrDispatched"];
      total["qrNotDispatched"] += obj["qrNotDispatched"];
      total["validNumber"] += obj["validNumber"];
      total["notValidNumber"] += obj["notValidNumber"];
      
      // console.log('total:', total)
    }

    tableData.push(total);
    // console.log("total:", total);

    // console.log('tableData:', tableData);
    const mergedData=makeGroupByRelation(tableData);
    // console.log('mergedData:', mergedData);
    return {
      header: Object.keys(tableData[0]),
      benData: mergedData,
    };

  }
};

const makeGroupByRelation=(data:any)=>{
  
  const mergedData: Record<string, any> = {
    
  };
  // console.log('mergedData:--first', data);
  data.forEach((row: any) => {
    const relation= row.relationship;
    console.log('relation:', relation)
    const shouldMergeUnderOthers =
    relation?.toLowerCase()==="other" ||
    relation?.toLowerCase()==="others" ||
    relation?.toLowerCase()==='father-in-law' ||
    relation?.toLowerCase()==='mother-in-law'||relation===""|| relation?.toLowerCase()==='grand mother'||relation?.toLowerCase()==='grand father'||relation?.toLowerCase()==='sister'||relation?.toLowerCase()==='brother'||relation?.toLowerCase()==='friend';
    // console.log('shouldMergeUnderOthers:', shouldMergeUnderOthers);
    if(shouldMergeUnderOthers){
      if (mergedData['Others']) {
      //  console.log(' row.totalUser:',  row);
      includeValuesToExisting(mergedData,'Others',row);
      }else{
        mergedData['Others'] = {...row};
      }
    }
    else{
      const isSon=relation.toLowerCase().startsWith('son');
      const isDaughter=relation.toLowerCase().startsWith('daughter');

      if(isSon){
        if (mergedData['Son']) {
          //  console.log(' row.totalUser:',  row.totalUser);
           includeValuesToExisting(mergedData,'Son',row);
        }else{
          mergedData['Son'] = {...row};
        }
      }else if(isDaughter){
        if (mergedData['Daughter']) {
          //  console.log(' row.totalUser:',  row.totalUser);
           includeValuesToExisting(mergedData,'Daughter',row);
        }else{
          mergedData['Daughter'] = {...row};
        }
      }else{

        mergedData[relation] = {...row};
      }    
    
    }
  });
  // console.log('mergedData:--final', mergedData)
const getValues=Object.values(mergedData);
  return getValues

}

const includeValuesToExisting = (mergedData: any,key: any, row: any) => {
  // console.log('row:', row,mergedData);
  if (mergedData[key]) {
      mergedData[key].notValidNumber += row?.notValidNumber;
      mergedData[key].totalUser += row.totalUser;
      mergedData[key].activatedUser += row.activatedUser;
      mergedData[key].notActivatedUser += row.notActivatedUser;
      mergedData[key].address += row.address;
      mergedData[key].basicDetails += row.basicDetails;
      mergedData[key].emergencyContact += row.emergencyContact;
      mergedData[key].insurancePolicies += row.insurancePolicies;
      mergedData[key].medicalInfo += row.medicalInfo;
      mergedData[key].preferredHospital += row.preferredHospital;
      mergedData[key].qrDispatched += row.qrDispatched;
      mergedData[key].qrNotDispatched += row.qrNotDispatched;
      mergedData[key].validNumber += row.validNumber;
  } else {
      console.log(`Key '${key}' not found in mergedData.`);
  }
  // console.log('mergedData:', mergedData);
}

