import { ShowAlert } from "../../../GlobalComponent/Alerts/Alert"
import { addBenToJob, createJob, deleteBenFromJob, getSingleJob, printingData, updateBenStatus, updateJobStatus } from "../../../Http/Index"
import * as XLSX from 'xlsx';
export const getPrintingData = async (setData: any) => {
    try {
        let response = await printingData();
        let data = response?.data?.reverse();
        setData(data);
    } catch (error: any) {
        console.error("Error while geting printing data", error)
        ShowAlert("warning", "Something Went Wrong")
    }
}

export const getSingleJobData = async (jobId: string, setData: any) => {
    try {
        let response = await getSingleJob(jobId);
        const data = response.data;
        let sortedData = data.beneficiaryList.sort((a: any, b: any) => {
            const nameA = a?.subscriberId?.toUpperCase();
            const nameB = b?.subscriberId?.toUpperCase();
            const benIdA = a?.benId?.toUpperCase();
            const benIdB = b?.benId?.toUpperCase();

            // First, compare by subscriberId
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            // If subscriberIds are equal, then compare by benId
            if (benIdA < benIdB) {
                return -1;
            }
            if (benIdA > benIdB) {
                return 1;
            }

            // If both subscriberId and benId are equal
            return 0;
        });


        return {
            data: sortedData,
            total: data.beneficiaryList.length,
            jobName: data.name,
            description: data.description,
            status: data.status
        }
    } catch (error) {
        console.log(error, "Error while geting printing data");
        ShowAlert("warning", "Something went wrong");
    }
}


export const deleteBenFromJobAction = async (jobid: string, id: string) => {
    try {
        await deleteBenFromJob(jobid, id);
        ShowAlert("success", "Beneficiary Deleted Successfully");
    } catch (error) {
        console.log(error, "Error while deleting beneficiary from job");
        ShowAlert("warning", "Something went wrong");
    }
}

export const updateBenStatusAction = async (jobid: string, id: string, status: string) => {
    const payload = {
        jobId: jobid,
        _id: id,
        status
    }
    try {
        await updateBenStatus(payload);
        ShowAlert("success", "Status Updated Successfully");
    } catch (error) {
        console.log(error, "Error while updating beneficiary status");
        ShowAlert("warning", "Something went wrong");
    }
}

export const createNewJob = async (name: string, description: string, setData: any) => {
    try {
        const payload = {
            name,
            description,
            agentUserId: "Testing",
            status: "New"
        }

        let data:any = await createJob(payload)
        setData((prev: any) => [...prev, data.data].reverse());
    } catch (error: any) {
        console.error("Error while geting printing data", error)
        ShowAlert("warning", error.response.data)
    }
}

export const addBeneficiariesToJob = async (jobId: string, payload: any) => {
    try {
        await addBenToJob(jobId, payload);
    } catch (error) {
        console.log(error, "Error while adding beneficiaries to job");
        ShowAlert("warning", "Something went wrong");
    }
}

export const updateJobStatusAction = async (jobid: string, status: string) => {
    const payload = {
        status
    }
    try {
        await updateJobStatus(jobid, payload);
    } catch (error) {
        console.log(error, "Error while updating job status");
        ShowAlert("warning", "Something went wrong");
    }
}

export const downloadExcel = (data: any, filename: any) => {
    const worksheet = XLSX.utils.json_to_sheet(data.qr);
    const workbook = XLSX.utils.book_new();
    const worksheet1 = XLSX.utils.json_to_sheet(data.address);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Qr Code");
    XLSX.utils.book_append_sheet(workbook, worksheet1, "Address");
    XLSX.writeFile(workbook, filename);
};