import { DynamicTable } from "../../../GlobalComponent/CustomTable/DynamicTable";
import { tableSeparator } from "../Action/action";

interface DynamicTableWrapperProps {
  data?: any;
}
export const DynamicTableWrapper: React.FC<DynamicTableWrapperProps> = ({
  data,
}) => {
  const getData: any = tableSeparator(data);
  // console.log("getData:", getData);
  const handleClick=()=>{
    console.log('clicked');
  }
  return (
    <>
      <div style={{ marginTop: "20px" }}>
        {getData && (
          <DynamicTable handleClick={handleClick} headers={getData?.header} benData={getData?.benData} />
        )}
      </div>
    </>
  );
};
