import { SearchByRadius } from "../../../Http/Index";

export const searchCompanyList=async(companyName:string,pageSize:number,pageNumber:number,radius:number)=>{
    try{
        const res=await SearchByRadius(companyName,pageSize,pageNumber,radius)
        return res.data
    }catch(e){
        throw e;
    }

}