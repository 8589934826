import React, { useState } from "react";
import { Box, Table, Thead, Tbody, Tr, Th, Td, Button, Spinner, Text, Select } from '@chakra-ui/react';
import { deleteUserData } from "./ManageUserFun";
import { MdDelete } from "react-icons/md";

export const UserDataTable: React.FC<any> = ({ data, searchRef, page, limit, handlePage, handleLimit, totalPages }) => {
    const [isLoading, setIsLoading] = useState<null | number>(null)

    const deleteUserDataHandle = async (userId: string, ind: number) => {
        setIsLoading(ind)
        let res = await deleteUserData({ userId })
        if (res) {
            searchRef.current?.click()
        }
        setIsLoading(null)
    }

    return (
        <Box>
            <Table variant="unstyled" >
                <Thead>
                    <Tr>
                        <Th>Collection</Th>
                        <Th>Name</Th>
                        <Th>Email</Th>
                        <Th>Beneficiary/Subscriber Id</Th>
                        <Th>User ID</Th>
                        <Th>Relationship To Subscriber</Th>
                        <Th>Delete</Th>
                    </Tr>
                </Thead>
                <Tbody >
                    {data?.map((item: any, ind: number) => {
                        return (
                            <Tr>
                                <Td>{item.collection}</Td>
                                <Td>{item.collection === "Beneficiary" ? item.personalInfo.fullName : item.name}</Td>
                                <Td>{item.collection === "Beneficiary" ? item.personalInfo.email : item.email}</Td>
                                <Td>{item?._id}</Td>
                                <Td>{item.userId}</Td>
                                <Td>{item?.personalInfo?.relationshipToSubscriber}</Td>
                                <Td>
                                    <Button bg={"#E2E8F0"} onClick={() => deleteUserDataHandle(item.userId, ind)}
                                        isDisabled={item.isDeleted}
                                    >
                                        {isLoading !== ind && item.isDeleted && "Deleted"}
                                        {isLoading !== ind && item.isDeleted !== true && <MdDelete color="red" />}
                                        {isLoading === ind && <Spinner
                                            thickness='4px'
                                            speed='0.65s'
                                            emptyColor='gray.200'
                                            color='blue.500'
                                            size='md'
                                        />}
                                    </Button>
                                </Td>
                            </Tr>
                        )
                    })}
                </Tbody>
            </Table>
            <Box display={"flex"} mt={"20px"} gap={"20px"} justifyContent={"center"} ml={"80px"} alignItems={"center"} mb={"20px"}>
                <Button onClick={(e) => handlePage(-1)} isDisabled={page === 1} colorScheme="green">Prev</Button>
                <Text>{`${page}/${totalPages}`}</Text >
                <Button onClick={(e) => handlePage(1)} isDisabled={page === totalPages} colorScheme="green">Next</Button>
                <Select onChange={handleLimit} width={"fit-content"}>
                    <option value={"10"}>10</option>
                    <option value={"50"}>50</option>
                    <option value={"100"}>100</option>
                </Select>
            </Box>
        </Box>
    )
}