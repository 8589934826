import { ReactNode } from "react"
import SideBar from "../SideBar/SideBar"
import style from "./Layout.module.css"
interface LayoutProps{
    children:ReactNode
}
export const Layout:React.FC<LayoutProps>=({children})=>{
    return(
        <>
        <SideBar/>
        <div className={style.mainContainer}>
            {children}
        </div>
        </>
    )
}