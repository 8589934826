import React from "react";
import { CustomeCheckBox } from "../FilterCheckBox/CustomeCheckBox";
import style from "./EmptyBeneficiaryFilters.module.css"

interface EmptyBeneficiaryFilterProps{
    onCheckboxChange:any
    getBenEmptyDetails:any
}
export const EmptyBeneficiaryFilter:React.FC<EmptyBeneficiaryFilterProps>=({getBenEmptyDetails,onCheckboxChange})=>{
    
      const handleCatchCheckbox = (value: any[]) => {
        // console.log("value:", value);
        
        onCheckboxChange( value[0],!value[1])
      };
      // console.log('getBenEmptyDetails:', getBenEmptyDetails)
    return(
        <>
         <div className={style.grid}>
          {Object.entries(getBenEmptyDetails).map((el: any) => {
            //  const [label, value] = el;
            return (
              <React.Fragment key={el}>
                <CustomeCheckBox
                  label={el}
                  checked={el[1]}
                  onChange={handleCatchCheckbox}
                />
              </React.Fragment>
            );
          })}
        </div>
        </>
    )
}