import { updateBulkEmailJob } from "../../../Http/Index"


export const updateBenToJob = async (id: string, data: any) => {
    try {
        let payload = { jobData: data }
        let response = await updateBulkEmailJob(id, payload)
        return response.data
    } catch (error: any) {
        throw error
    }
}