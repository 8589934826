import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { deleteBenFromJobAction, downloadExcel, getSingleJobData, updateBenStatusAction, updateJobStatusAction } from '../Printing/Actions/printing.action';
import SideBar from '../../GlobalComponent/SideBar/SideBar';
import style from '../Printing/printing.module.css'
import { Box, Button, Divider, Flex, Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Table, Tbody, Td, Text, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react';
import { MdDelete } from "react-icons/md";
export const PrintingDetails: React.FC = () => {
    const { jobid } = useParams();
    const [data, setData] = useState<any>({}) // eslint-disable-line
    const [compiledData, setCompiledData] = useState<any>([]) // eslint-disable-line
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { isOpen: isDispatchOpen, onOpen: onDispatchOpen, onClose: onDispatchClose } = useDisclosure();
    const [loading, setLoading] = useState(false);
    const handelDeleteBenFromList = async (el: any) => {
        try {
            if (jobid) {
                await deleteBenFromJobAction(jobid, el._id);
                await getJobData();
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleBenStatusChange = async (id: string, status: string) => {
        try {
            if (jobid) {
                await updateBenStatusAction(jobid, id, status);
                await getJobData();
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleQrPrint = async () => {
        let qrData = compiledData.map((el: any) => {
            return {
                "Ben Id": el.benId,
                "Subscriber Name": el.subscriberName,
                "Ben Name": el.benName,
                "Relation": el.relation,
                "QR Label": el.qrLabel,
                "QR Input": el.qrInput
            }
        })

        let addressData = compiledData.map((el: any) => {
            return {
                "Ben Id": el.benId,
                "Subscriber Id": el.subscriberId,
                "Subscriber Company": el.companyName,
                "Subscriber Name": el.subscriberName,
                "Ben Name": el.benName,
                "Relation": el.relation,
                "Address": el.relation.toLowerCase() === "self" ? `To,\n${el.benName},\n${el?.address?.addressLine1 ? el?.address?.addressLine1 + ",\n" : ""}${el?.address?.addressLine2 ? el?.address?.addressLine2 + ",\n" : ""} ${el?.address?.city ? el?.address?.city + ", " : ""} ${el?.address?.state ? el?.address?.state + ", " : ""} ${el?.address?.pinCode || ""}\n${el?.mobile || ""}` : `C/O ${el.subscriberName}`
            }
        })

        const dataToDownload = {
            qr: qrData,
            address: addressData
        }

        downloadExcel(dataToDownload, "qrCode_" + data.jobName + ".xlsx");
        if (data.status === "Created" || data.status === "Submitted") {
            // update job status to printed;
            try {
                if (jobid) {
                    await updateJobStatusAction(jobid, "Printed");
                    await getJobData();
                }
            } catch (error) {
                console.log(error)
            }
        }
    }

    const updateJobStatus = async (status: string) => {
        if (jobid) {
            try {
                await updateJobStatusAction(jobid, status);
                await getJobData();
                onClose();
                onDispatchClose()
            } catch (error) {
                console.log(error)
            }
        }
    }

    const getJobData = async () => {
        setLoading(true);
        if (jobid) {
            try {
                let data: any = await getSingleJobData(jobid, setData);
                if (data) {
                    setData(data);
                }

                console.log(data)

                let rawData = data.data.map((el: any) => {
                    return {
                        _id: el._id,
                        benId: el?.benId,
                        subscriberName: el?.subscriberName,
                        benName: el?.personalInfo?.fullName,
                        relation: el?.personalInfo?.relationshipToSubscriber || "",
                        qrLabel: el?.personalInfo?.fullName + " C/o " + el?.subscriberName,
                        qrInput: `https://www.pococare.com/trigger-emergency/${el.benId}`,
                        status: el.status,
                        companyName: el?.companyName || "",
                        mobile: el?.personalInfo?.mobile?.number || "Mobile : N/A",
                        address: el.address || null,
                        subscriberId: el?.subscriberId || "",
                        isDuplicate: el?.isDuplicate || false
                    }
                })

                setCompiledData(rawData);
                setLoading(false);
            } catch (error) {
                console.log("Error", error);
                setLoading(false);
            }

        }
    }

    const handelAddMoreBen = () => {
        return navigate('/print/' + jobid);
    }

    useEffect(() => {
        getJobData();
    }, [jobid]) // eslint-disable-line

    return (
        <>
            <SideBar />
            <Box flex={1} p={1} mt={30} ml={30} bg="white">
                <div className={style.mainContainer}>
                    <Heading textAlign={"center"}>{data.jobName}</Heading>
                    <Heading size={"sm"} textAlign={"center"} color={"teal"}>Status: {data.status}</Heading>
                    <Heading size='md' as={"h6"} mt={"1px"}>Description:</Heading>
                    <Text mb={"20px"}>{data.description}</Text>
                    {
                        compiledData.filter((el: any) => el.isDuplicate === "true").length ? <Heading color={"red"} size='md' as={"h6"} mt={"1px"} mb={"20px"}>Duplicate Beneficiaries: {compiledData.filter((el: any) => el.isDuplicate === "true").length}</Heading> : ""
                    }
                    <Flex>
                        {
                            (data.status === "Created" && compiledData.length) ? <Button ml={"10px"} variant={"solid"} colorScheme='facebook' onClick={handelAddMoreBen}>Add More Beneficiaries</Button> : ""
                        }
                        {
                            (data.status === "Created" && compiledData.length) ? <Button ml={"10px"} variant={"solid"} colorScheme='red' onClick={() => onOpen()}>Submit</Button> : ""
                        }
                        {
                            (data.status === "Submitted" || data.status === "Printed" || data.status === "Completed" || data.status === "Dispatched") && <Button ml={"10px"} variant={"solid"} colorScheme='facebook' onClick={handleQrPrint}>Download Excel</Button>
                        }
                        {
                            (data.status === "Submitted" || data.status === "Printed" || data.status === "Completed" || data.status === "Dispatched") && compiledData.length <= 100 && <Button ml={"10px"} variant={"solid"} colorScheme='facebook' onClick={() => navigate(`/generate-qr/${jobid}`)}>Print QR</Button>
                        }
                        {
                            (data.status === "Printed") && <Button ml={"10px"} variant={"solid"} colorScheme='telegram' onClick={onDispatchOpen}>Mark All As Dispatched</Button>
                        }
                    </Flex>


                    <div className={style.tableContainer}>
                        {
                            loading ? <Heading>Loading...</Heading> : compiledData.length > 0 ? <>
                                <Table variant="simple">
                                    <Thead>
                                        <Tr>
                                            <Th
                                                position="sticky"
                                                top={0}
                                                bgColor="white"
                                                zIndex="sticky"
                                            >
                                                Ben ID
                                            </Th>
                                            <Th
                                                position="sticky"
                                                top={0}
                                                bgColor="white"
                                                zIndex="sticky"
                                            >
                                                SubscriberName
                                            </Th>
                                            <Th
                                                position="sticky"
                                                top={0}
                                                bgColor="white"
                                                zIndex="sticky"
                                            >
                                                Beneficiary Name
                                            </Th>
                                            <Th
                                                position="sticky"
                                                top={0}
                                                bgColor="white"
                                                zIndex="sticky"
                                            >
                                                Relation
                                            </Th>
                                            {/* <Th
                                        position="sticky"
                                        top={0}
                                        bgColor="white"
                                        zIndex="sticky"
                                    >
                                        QR Label
                                    </Th> */}
                                            <Th
                                                position="sticky"
                                                top={0}
                                                bgColor="white"
                                                zIndex="sticky"
                                            >
                                                QR Input
                                            </Th>
                                            <Th
                                                position="sticky"
                                                top={0}
                                                bgColor="white"
                                                zIndex="sticky"
                                            >
                                                Address
                                            </Th>
                                            <Th
                                                position="sticky"
                                                top={0}
                                                bgColor="white"
                                                zIndex="sticky"
                                                colSpan={2}
                                            >
                                                Actions
                                            </Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {compiledData &&
                                            compiledData.map((el: any) => (
                                                <Tr
                                                    // style={{ cursor: "pointer" }}
                                                    key={el._id}
                                                    backgroundColor={el.isDuplicate === "true" ? "red.100" : "white"}
                                                >
                                                    <Td>{el.benId || "N/A"}</Td>
                                                    <Td>{el.subscriberName || "N/A"}</Td>
                                                    <Td>
                                                        {el.benName}
                                                    </Td>
                                                    <Td>
                                                        {el.relation}
                                                    </Td>
                                                    <Td>{el.qrInput}</Td>
                                                    <Td>{el.relation.toLowerCase() === "self" ? `To,\n${el.benName},\n${el?.address?.addressLine1 ? el?.address?.addressLine1 + ",\n" : ""}${el?.address?.addressLine2 ? el?.address?.addressLine2 + ",\n" : ""} ${el?.address?.city ? el?.address?.city + ", " : ""} ${el?.address?.state ? el?.address?.state + ", " : ""} ${el?.address?.pinCode || ""}\n${el?.mobile || ""}` : `C/O ${el.subscriberName}`}</Td>
                                                    {
                                                        (data.status === "Submitted" || data.status === "Printed" || data.status === "Dispatched") && <Td>{
                                                            <select value={el.status} onChange={(e: any) => handleBenStatusChange(el._id, e.target.value)}>
                                                                <option disabled={true} value="Added">Added</option>
                                                                <option value="Printed">Printed</option>
                                                                <option value="Dispatched">Dispatched</option>
                                                            </select>
                                                            // el.status
                                                        }</Td>
                                                    }
                                                    {
                                                        data.status === "Created" && <Td>{<Button onClick={() => handelDeleteBenFromList(el)} fontSize={"25px"} color={"red"} backgroundColor={"white"} variant={"solid"} colorScheme={"gray"}><MdDelete /></Button>}</Td>
                                                    }
                                                </Tr>
                                            ))}
                                    </Tbody>
                                </Table>
                            </> : <>
                                <Divider fontWeight={"extrabold"} borderColor={"telegram"} mt={"10px"} mb={"10px"} />
                                <Flex justifyContent={"center"} alignItems={"center"} borderRadius={"10px"} p={5} flexDirection={"column"}>
                                    <Heading color={"red"} size={"lg"}>No Records Found</Heading ><br />
                                    <Button ml={"10px"} variant={"solid"} colorScheme='facebook' onClick={handelAddMoreBen}>Add Records</Button>
                                </Flex>
                            </>
                        }
                    </div>
                </div>
            </Box>

            {/* Submit Conformation Modal */}

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Confirmation!</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {/* <Lorem count={2} /> */}

                        Once submitted, beneficiaries cannot be added or deleted.
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={onClose}>
                            Close
                        </Button>
                        <Button onClick={() => updateJobStatus("Submitted")} variant='solid' colorScheme='red'>Submit</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            {/* Dispatch Modal */}

            <Modal isOpen={isDispatchOpen} onClose={onDispatchClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Confirmation!</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {/* <Lorem count={2} /> */}

                        Are you sure to dispatch?
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={onDispatchClose}>
                            Close
                        </Button>
                        <Button onClick={() => updateJobStatus("Dispatched")} variant='solid' colorScheme='red'>Submit</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>


            {/* Add More Beneficiaries Modal */}

            <Modal isOpen={isDispatchOpen} onClose={onDispatchClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Confirmation!</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {/* <Lorem count={2} /> */}

                        Are you sure to dispatch?
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={onDispatchClose}>
                            Close
                        </Button>
                        <Button onClick={() => updateJobStatus("Dispatched")} variant='solid' colorScheme='red'>Submit</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}
