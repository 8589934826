import style from "./waMessage.module.css"
interface WaMessageDetailsProps{
    data:any,
  
}
export const WaMessageDetails:React.FC<WaMessageDetailsProps> = ({data}) => {
    // console.log('data:', data);
    return (
        <div>
            <div className={style.center}>
            <h1>WhatsApp Job Name-</h1>
            <h1>{data.jobName}</h1>
            </div>
        </div>
    )
}