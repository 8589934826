import { Layout } from "../../GlobalComponent/Layout/Layout"
import BenAddressData from "../BenAddressData/BenAddressData"

export const BenFilters=()=>{
    return(
        <>
        <Layout>
            <BenAddressData/>
        </Layout>
        </>
    )
}