export function flattenObject(obj:any, parentKey = '') {
    let result:any = {};
  
    for (const key in obj) {
      let newKey = parentKey ? key : key;
      // console.log('newKey:', newKey);
      if (newKey === "fullName") {
        newKey = "benName";
      }
      if (newKey === "number" && parentKey==="mobile") {
        newKey = "benMobile";
      }
      if (newKey === "number" && parentKey==="whatsappMobile") {
        newKey = "benWhatsappMobile";
      }
      if(newKey!=="countryCode"){
        if (typeof obj[key] === 'object' && obj[key] !== null  ) {
          result = { ...result, ...flattenObject(obj[key], newKey) };
        } else {
          result[newKey==="_id"?"benId":newKey] = obj[key];
        }
      }
    }
  
    return result;
  }

  export const getRemovedBenId=(alreadySelectedData:any,benId:any)=>{
    // console.log('alreadySelectedData:', alreadySelectedData,benId)
    const res= alreadySelectedData.findIndex((benData:any)=>benData.benId===benId);
    // console.log('res:', res);
return res
  }