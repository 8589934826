import { getWaTemplateDetails } from "../Http/Index";
export const getTemplateByVariable = async ({ serviceName,templateName }: any) => {
    try{
        const res=await getWaTemplateDetails(serviceName,templateName);
        return res;
    }catch(e:any){
        alert(e?.response?.data?.message||"some technical issue in getting template details");
        console.log(e);
    }
}
export const getVariable=async(templateName:any)=>{
    // console.log('templateName:', templateName);
 const response=await getTemplateByVariable({serviceName:"api-whatsapp-service",templateName})
//  console.log('response:', response);
return response?.data?.TEMPLATE_VARS
    
}
