import { Box, Button, Flex, Input, Link, Select, Spinner } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import style from "./UploadUserExcel.module.css"
import ExcelJS from "exceljs"
import * as XLSX from 'xlsx';
import { getCompanyList, handleExcelSheetReq } from "./Action/uploadUserExcelFun";
import { FaDownload } from "react-icons/fa";
interface companyObject {
    companyID: string,
    companyName: string
}

export const UploadUserExcel: React.FC = () => {
    const [excelState, setExcelState] = useState<any>(null)
    const [companyList, setCompanyList] = useState<null | companyObject[]>(null)
    const [selectedCompany, setSelectedCompany] = useState<string | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    useEffect(() => {
        getCompanyListFun()
    }, [])

    const getCompanyListFun = async () => {
        let responseCompanyList = await getCompanyList()
        if (responseCompanyList?.data) {
            responseCompanyList?.data?.forEach((item: any) => {
                setCompanyList((prev) => {
                    if (prev) {
                        return [...prev, { companyName: item.companyName, companyID: item._id }]
                    } else {
                        return [{ companyName: item.companyName, companyID: item._id }]
                    }
                })
            })
        }
    }

    const handleInputCompany = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedCompany(e.target.value)
    }

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        setExcelState(e.target?.files?.[0])
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!excelState) {
            alert("Please Choose excel file")
            return
        }
        if (!selectedCompany) {
            alert("Please select company")
            return
        }
        console.log("selectedCompany", selectedCompany)

        const reader = new FileReader();
        reader.onload = (event) => {
            if (event.target) {
                const data = new Uint8Array(event.target.result as ArrayBuffer);
                const workbook = XLSX.read(data, { type: 'array' });
                const sheet = workbook.Sheets["CreateUser"];
                const parsedData = XLSX.utils.sheet_to_json(sheet, { header: 1, defval: "" }) as string[][];
                performAction(parsedData)
            }
        };
        reader.readAsArrayBuffer(excelState);
    }

    const performAction = async (parsedData: string[][]) => {

        if (parsedData.length === 0) {
            alert("Sheet is empty or sheet name might be different")
            return
        }

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('CreateUser')
        worksheet.addRow(parsedData[0])
        worksheet.addRow(parsedData[1])

        let excelFormatStatus = checkExcelFormat(parsedData[1])

        if (excelFormatStatus === false) {
            alert("Please upload excel in defined format")
            return
        }
        setIsLoading(true)
        for (let i = 2; i < parsedData.length; i++) {

            if (parsedData[i].length === 0) {
                continue
            }

            let row = worksheet.addRow(parsedData[i])

            let fullName = parsedData[i][1]?.toString()?.trim();
            let relationshipToSubscriber = parsedData[i][2]?.toString()?.trim();
            let email = parsedData[i][3]?.toString()?.toLowerCase()?.trim();
            let subscriberEmail = parsedData[i][4]?.toString()?.toLowerCase()?.trim();
            let countryCode = parsedData[i][5] || null;
            let number = parsedData[i][6] || null
            let city = parsedData[i][7] ? parsedData[i][7]?.toString()?.trim() : '';
            let pinCode = parsedData[i][8] || null;
            let companyId = selectedCompany;
            let addBeneficiaryAllowed: any = parsedData[i][9];
            let numberOfBenAllowed = parsedData[i][10];
            let gender = parsedData[i][11]?.toString()?.trim() || null;
            let originalDateString = parsedData[i][12];
            let policyId = parsedData[i][13]?.toString()?.trim()
            let nameOfInsurer = parsedData[i][14]?.toString()?.trim() || null;
            let tpaName = parsedData[i][15]?.toString()?.trim() || null;

            if (relationshipToSubscriber) {
                let newRelationshipToSubscriber = relationshipToSubscriber.charAt(0).toUpperCase() + relationshipToSubscriber.slice(1).toLowerCase()
                relationshipToSubscriber = newRelationshipToSubscriber
            }

            if (relationshipToSubscriber !== "Self" && !addBeneficiaryAllowed) {
                addBeneficiaryAllowed = false
                numberOfBenAllowed = "0"
            }

            if (gender) {
                let newGender = gender.charAt(0).toUpperCase() + gender.slice(1).toLowerCase()
                gender = newGender
            }

            if (originalDateString) {
                const date = XLSX.SSF.parse_date_code(originalDateString);
                originalDateString = `${date.d}/${date.m}/${date.y}`;
                row.getCell(13).value = originalDateString
            }

            let subscriberData: any = {
                fullName,
                relationshipToSubscriber,
                city,
                pinCode,
                email,
                subscriberEmail,
                addBeneficiaryAllowed,
                companyId,
                gender,
                dateOfBirth: originalDateString,
                policyId,
                nameOfInsurer,
                tpaName,
                numberOfBenAllowed
            }

            for (let key in subscriberData) {
                if (subscriberData[key] === "" || subscriberData[key] === undefined || subscriberData[key] === null) {
                    delete subscriberData[key]
                }
            }
            console.log("subscriberData", subscriberData)

            let inputValidationFlag = inputValidation({ ...subscriberData, number, countryCode })

            if (number && countryCode) {
                subscriberData["mobileNo"] = { countryCode: Number(countryCode), number: Number(number) }
            }

            if (inputValidationFlag) {
                row.getCell(17).value = inputValidationFlag
                row.getCell(17).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFF0000' }
                };

            } else {

                console.log("subData", subscriberData)
                let response: any = await handleExcelSheetReq(subscriberData)
                // console.log("response", response)
                if (response.response?.data?.statusCode === 409) {
                    // console.log("true")
                    row.getCell(17).value = response.response?.data?.message
                    row.getCell(17).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFF0000' }
                    };
                } else if (response.status === 201) {
                    row.getCell(17).value = response.data
                    row.getCell(17).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FF00FF00' }
                    };
                } else {
                    row.getCell(17).value = response.response?.data?.message
                    row.getCell(17).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFF0000' }
                    };
                }
            }


        }
        worksheet.columns.forEach((column) => column.width = 20)
        worksheet.eachRow((row) => {
            row.eachCell((cell) => {
                cell.alignment = { horizontal: 'left' };
                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                };
            });
        });

        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        const url = window.URL.createObjectURL(blob);


        const a = document.createElement('a');
        a.href = url;
        a.download = 'add_user_modified_excel.xlsx';


        document.body.appendChild(a);
        a.click();


        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        setIsLoading(false)
    }

    function inputValidation(data: any) {
        let validationTxt = '';

        const relationship_array: any = {
            Mother: 1,
            Father: 1,
            Daughter: 1,
            Son: 1,
            Self: 1,
            Sister: 1,
            Brother: 1,
            'Grand Mother': 1,
            'Grand Father': 1,
            'Father-in-law': 1,
            'Mother-in-law': 1,
            'Spouse': 1,
            'Others': 1,
        }
        if (!relationship_array[data.relationshipToSubscriber]) {
            validationTxt += 'Relationship should be valid, and one of enum value, '
        }

        if (data.pinCode) {
            if (!Number(data.pinCode)) {
                validationTxt += 'pincode should be number, '
            }
        }

        if (data.pinCode) {
            if (data.pinCode > 999999 || data.pinCode < 100000) {
                validationTxt += 'pincode should be 6 digit, '
            }
        }

        if (!data.email) {
            validationTxt += 'Email should not be empty, '
        }

        if (data.email) {
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            if (!data.email.match(emailRegex)) {
                validationTxt += 'Email Should be valid, '
            }
        }

        if (!data.subscriberEmail) {
            validationTxt += 'Subscriber email should be defined, '
        }

        if (data.subscriberEmail) {
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            if (!data.subscriberEmail.match(emailRegex)) {
                validationTxt += 'Subscriber Email Should be valid, '
            }
        }

        if (!data.fullName || typeof (data.fullName) !== 'string') {
            validationTxt += 'Name should be defined & it should be string, '
        }


        if (data.relationshipToSubscriber === "Self") {
            if (data.addBeneficiaryAllowed === "") {
                validationTxt += 'Need to choose addBeneficiaryAllowed true or false, '
            }
            if (data.email !== data.subscriberEmail) {
                validationTxt += 'User Email and Subscriber Email should be same, '
            }

            if (!data.numberOfBenAllowed || data.numberOfBenAllowed === "0") {
                return;
            }

            if (data.numberOfBenAllowed === "") {
                validationTxt += 'Need to privide numberOfBenAllowed, '
            }
            if (typeof data.numberOfBenAllowed === 'string') {
                // Try parsing the string to a number
                const parsedNumber = parseFloat(data.numberOfBenAllowed);

                // Check if the parsing was successful and the number is greater than 0
                if (!isNaN(parsedNumber) && parsedNumber > 0) {
                    //return "Valid";
                } else {
                    validationTxt += 'numberOfBenAllowed should be number, '
                }
            }

            // Check if the input is a number and greater than 0
            if (typeof data.numberOfBenAllowed === 'number' && data.numberOfBenAllowed > 0) {
                //return "Valid";
            } else {
                validationTxt += 'numberOfBenAllowed should be number and greater than 0, '
            }
        }
        if (data.number) {
            if (!Number(data.number)) {
                validationTxt += 'mobile number should be number, '
            }
            if (!Number(data.countryCode)) {
                validationTxt += 'countryCode should be present and it should be number, '
            }
            if (data.number > 9999999999 || data.number < 1000000000 || data.countryCode < 0 || data.countryCode > 999) {
                validationTxt += 'Subscriber number should be valid, number should be 10 digit and countryCode should be valid, '
            }
        }

        if (!data.companyId) {
            validationTxt += 'companyID should be present, '
        }

        return validationTxt
    }

    const checkExcelFormat = (data: String[]) => {

        if (data[0] !== "Sl. No.") {
            return false
        } else if (data[1] !== "Beneficiary Name") {
            return false
        } else if (data[2] !== "Relationaship with employee") {
            return false
        } else if (data[3] !== "Email Id.") {
            return false
        } else if (data[4] !== "Subscriber email") {
            return false
        } else if (data[5] !== "Country Code") {
            return false
        } else if (data[6] !== "Mobile No.") {
            return false
        } else if (data[7] !== "City") {
            return false
        } else if (data[8] !== "Pincode") {
            return false
        } else if (data[9] !== "addBeneficiaryAllowed") {
            return false
        } else if (data[10] !== "number of beneficiaries allowed") {
            return false
        } else if (data[11] !== "Gender") {
            return false
        } else if (data[12] !== "DOB (dd-mm-yyyy)") {
            return false
        } else if (data[13] !== "InsurancePolicyId") {
            return false
        } else if (data[14] !== "Insurer") {
            return false
        } else if (data[15] !== "TPA Name") {
            return false
        } else if (data[16] !== "Status") {
            return false
        }
    }

    const handleClearFilter = () => {
        window.location.reload()
    }

    return (
        <Box alignItems={"center"}>
            <form className={style.excelForm} onSubmit={handleSubmit} encType="multipart/form-data">
                <Button type="submit" padding={"20px"} width={"200px"} colorScheme="green" isDisabled={isLoading}>
                    {isLoading === false && "Add Users"}
                    {isLoading && <Spinner
                        thickness='4px'
                        speed='0.65s'
                        emptyColor='gray.200'
                        color='blue.500'
                        size='md'
                    />}
                </Button>
                <Input type="file" onChange={handleInput} width={"250px"} accept=".xls,.xlsx,application/vnd.ms-excel" />
                <Select onChange={handleInputCompany} placeholder="Select Partner Company" w={"fit-content"}>
                    {companyList &&
                        companyList.map((item, ind) => <option value={item.companyID}>{item.companyName}</option>)
                    }
                </Select>
                <Link href="https://pocostorage.blob.core.windows.net/others/createUser%20Tempate%20V2.xlsx"><Flex alignItems={"center"} gap={"10px"}><FaDownload color="green" /> Download Template</Flex></Link>
                <Button colorScheme="green" onClick={handleClearFilter}>Clear Filters</Button>
            </form>
        </Box>
    )
}