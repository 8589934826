const initialFields = {
    personalInfo: [
      "dateOfBirth",
      "relationshipToSubscriber",
      "fullName",
      "gender",
      "email",
      "image",
      "mobile"
    ],
    address: [
      "_id",
      "addressLine1",
      "addressLine2",
      "landmark",
      "isLiftAvailable",
      "isReachableByAmbulance",
      "label",
      "city",
      "state",
      "country",
      "pinCode",
      
    ],
    preferredHospital:[
      "id",
      "hospitalName",
      "distance",
    ],
    
   
    emergencyContacts: [
      "_id",
      "fullName",
      "email",
      "isPrimary",
      "relationshipToBeneficiary",
      "mobile"
     ],
    
    insurancePolicies: [
      "_id",
      "typeOfPolicy",
      "tpaName",
      "policyId",
      "docUrl",
      "nameOfInsurer",
    ],
    company: ["id", "name"],
    subscriberDetails:["subscriberName","subscriberMobile"],
    // medicalInfo:[
    //   "chronicConditions",
  
    // ],
    // pastMedicalRecords:["all","_id","docsUrl","description"],
    // currentMedications:["all","docsUrl","description"],
    // allergiesToMedications:["description"],
    // recentHospitalizations:["description","doctorsInformation"],
    // generalInfo: ["mobilityStatus","bloodGroup","differentlyAble"]
  
  };
  interface Node {
    id: number;
    label: string;
    value: string;
    children?: Node[];
  }
   const generateNodes=(fieldsObject: any, parentLabel: string | null = null):Node[]=> {
    let count=0
      return Object.keys(fieldsObject).map((label, index) => ({
          id: ++count,
          label: label,
          value:label,
          children: fieldsObject[label].map((field: string, fieldIndex: number) => ({
              id: ++count,
              label: field,
              value:`${label}.${field}`
            })),
        }));
    }


    export const relationShipValues = [
      { rel: "--/--", name: "", index: 0, selected: false, id: 0 },
      { rel: "Self", name: "", index: 0, selected: false, id: 0 },
      { rel: "Father", name: "", index: 0, selected: false, id: 0 },
      { rel: "Mother", name: "", index: 1, selected: false, id: 0 },
      { rel: "Son", name: "", index: 2, selected: false, id: 0 },
      { rel: "Daughter", name: "", index: 3, selected: false, id: 0 },
      { rel: "Brother", name: "", index: 4, selected: false, id: 0 },
      { rel: "Sister", name: "", index: 5, selected: false, id: 0 },
      { rel: "Spouse", name: "", index: 6, selected: false, id: 0 },
      { rel: "Grand Mother", name: "", index: 7, selected: false, id: 0 },
      { rel: "Grand Father", name: "", index: 8, selected: false, id: 0 },
      { rel: "Father-in-law", name: "", index: 9, selected: false, id: 0 },
      { rel: "Mother-in-law", name: "", index: 10, selected: false, id: 0 },
      { rel: "Others", name: "", index: 11, selected: false, id: 0 }
  ];
export const initialStateForOutputField = generateNodes(initialFields);
