import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import ExcelJS from 'exceljs';
import saveAs  from 'file-saver';
import { Box, Button, Flex, HStack, Input } from '@chakra-ui/react';
import { Spinner } from 'react-activity';
import { deleteUserIdsByEmails } from './DeleteUsersInBulkFun';
import { Result } from './DeleteUsersInBulkTypes';
import { Link } from 'react-router-dom';
import { FaDownload } from 'react-icons/fa';

const DeleteUsersInBulk: React.FC = () => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [isLoading, setIsLoading] = useState(false)

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            setSelectedFile(event.target.files[0]);
        }
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        setIsLoading(true);

        if (!selectedFile) {
            alert("Please upload a file.");
            setIsLoading(false);
            return;
        }

        const reader = new FileReader();
        reader.onload = async (e: ProgressEvent<FileReader>) => {
            if (e.target) {
                const arrayBuffer = e.target.result as ArrayBuffer;
                const data = new Uint8Array(arrayBuffer);
                const workbook = XLSX.read(data, { type: 'array' });

                const emails: string[] = [];

                // Loop through each sheet in the workbook
                workbook.SheetNames.forEach(sheetName => {
                    const sheet = workbook.Sheets[sheetName];
                    const jsonData = XLSX.utils.sheet_to_json(sheet);

                    jsonData.forEach((row: any) => {
                        if (row['Subscriber Email']) {
                            emails.push(row['Subscriber Email']);
                        }
                    });
                });

                // validating email addresses length and subscribers ids length
                if (emails.length === 0) {
                    alert("No valid emails");
                    setIsLoading(false);
                    return;
                }

                // Make Api request to delete users
                if (emails.length > 0) {
                    try {
                        const results:Result[] = await deleteUserIdsByEmails(emails);

                        // Create a new Excel workbook and worksheet using ExcelJS
                        const workbook = new ExcelJS.Workbook();
                        const worksheet = workbook.addWorksheet('Results');

                        // Add headers
                        worksheet.columns = [
                            { header: 'Sr. No.', key:'srNo', width: 10 },
                            { header: 'Subscriber Email', key: 'email', width: 35 },
                            { header: 'SubscriberId', key: 'subscriberId', width: 30 },
                            { header: 'Status', key: 'status', width: 40 },
                        ];

                        // Add rows with styling
                        results.forEach((result: Result) => {
                            const row = worksheet.addRow(result);
                            let statusColor = 'ffffff';
                            statusColor = result.status === "UserData deleted successfully" ? "FF00FF00" : "FFFF0000";
                            row.getCell('status').fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb:statusColor},
                            };
                        });

                        // Generate Excel file and trigger download
                        const buffer = await workbook.xlsx.writeBuffer();
                        const blob = new Blob([buffer], { type: 'application/octet-stream' });
                        saveAs(blob, 'subscribers-deletion-status.xlsx');

                        alert("Process completed. Downloading the status report.");
                    } catch (error) {
                        // console.error("Error deleting users:", error);
                        alert("An error occurred while deleting users.");
                    } finally {
                        // Nullify the FileReader to clean up
                        reader.onload = null;
                        reader.onerror = null;
                        setIsLoading(false);
                        setSelectedFile(null);
                    }
                }
            }
            // Nullify the FileReader to clean up
            reader.onerror = (error) => {
                console.error("Error reading file:", error);
                alert("An error occurred while reading the file.");
                reader.onload = null;
                reader.onerror = null;
                setIsLoading(false);
                setSelectedFile(null);
            };
        };
        reader.readAsArrayBuffer(selectedFile);
    };

    return (
        <Box alignItems={"center"}>
            <form onSubmit={handleSubmit} encType="multipart/form-data">
                <HStack>
                    <Button type="submit" padding={"20px"} width={"200px"} colorScheme="green" isDisabled={isLoading} mr={7}>
                        {isLoading === false && "Delete Users"}
                        {isLoading && <Spinner
                            speed={0.65}
                            color='blue.500'
                            size={2}
                        />}
                    </Button>
                    <Input type="file" onChange={handleFileUpload} width={"250px"} accept=".xls,.xlsx,application/vnd.ms-excel" />
                    <Link to="https://developmentbucket.blob.core.windows.net/excels-files/deleteUsersInBulkTemplate.xlsx">
                        <Flex alignItems={"center"} gap={"10px"} ml={5}>
                            <FaDownload color="green" /> Bulk Delete Template
                        </Flex>
                    </Link>
                </HStack>
            </form>
        </Box>
    );
};

export default DeleteUsersInBulk;
