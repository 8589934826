import * as ExcelJS from "exceljs";
import saveAs from "file-saver";
import { generateSaSToken } from "../../../Helper/helper";
// import { BenData } from "./getAllBen";
let SaSToken=localStorage.getItem("sasToken");
const checkToken=async()=>{
  if(!SaSToken){

    (async()=>await generateSaSToken())();
    SaSToken=localStorage.getItem("sasToken");
  }
}

const constructDynamicTableField = async(data: any,headers:any,values:any,sasToken:string) => {
  await checkToken();
  // console.log('data:', data);
  // eslint-disable-next-line
  data?.forEach((el: any, index: number) => {
    let tableValues = [];
    let tableHeaders = [];
    // console.log('el:', el);
    if(el._id){
      tableHeaders.push("benId")
      tableValues.push({benId:el._id});
    }
    if (el.personalInfo) {
      let keys = gettingKeyFromObj(el.personalInfo);
      // let values = gettingValueFromObj(el.personalInfo);
      tableHeaders.push(...keys);
      tableValues.push({
        ...el.personalInfo,
        benName:el?.personalInfo?.fullName||"N/A",
        benMobile: el?.personalInfo?.mobile?.number || "null",
        benWhatsappBenMobile: el?.personalInfo?.whatsappMobile?.number || "null"
      });;
    }
    if (el.address) {
      const res = createPayloadForArray("address", el.address);
      tableHeaders.push(...res.parentName);
      tableValues.push(...res.arrayPayload);
      el.address.forEach((addressInside: any) => {
        if (addressInside.preferredHospital) {
          const res = createPayloadForArray(
            "preferredHospital",
            addressInside.preferredHospital
          );
          tableHeaders.push(...res.parentName);
          tableValues.push(...res.arrayPayload);
        }
      });
    }
    if (el.emergencyContacts) {
      // console.log("check", el);
      const getKeyValue=convertArrayToObject(el.emergencyContacts)
      const res = createPayloadForArray("EmContact", getKeyValue);
      // console.log("res:", res);
      tableHeaders.push(...res.parentName);
      tableValues.push(...res.arrayPayload);
    }
    if (el.insurancePolicies) {
      // generateSaSToken();
      const res = createPayloadForArray("insurance", el.insurancePolicies,sasToken);
      tableHeaders.push(...res.parentName);
      tableValues.push(...res.arrayPayload);
    }
    if (el.company) {
      // console.log("companyyyyyyy",el.company)
      if(el.company){
        let values = gettingValueFromObj(el?.company);
        tableHeaders.push("companyName");
        tableValues.push({ companyName: values.join(",") });
      }else{
        tableHeaders.push("companyName");
        tableValues.push({ companyName: "null" });
      }
    }
    if(el.subscriberDetails){
      let keys = gettingKeyFromObj(el.subscriberDetails);
      tableHeaders.push(...keys);
      tableValues.push(
        el.subscriberDetails          
      );;
    }
    values.push(tableValues);
    headers.push(tableHeaders);
  });
};
const createPayloadForArray = (fieldName: string, data: any,sasToken?:string) => {
  let arrayPayload: any = [];
  let parentName: any = [];
  data?.length &&
    data?.forEach((el: any, index: number) => {
      if (el) {
        const field = convertObjectToString(el,sasToken);
        parentName[index] = `${fieldName}.${index + 1}`;
        let currentName = `${fieldName}.${index + 1}`;
        arrayPayload[index] = { [currentName]: field };
      }
    });
  if (!parentName.length) {
    parentName.push(fieldName);
  }
  if (!arrayPayload.length) {
    arrayPayload.push(`No ${fieldName}`);
  }
  // console.log("arrayPayload:", arrayPayload);
  return { parentName, arrayPayload };
};

const convertObjectToString = (obj: any,sasToken?:string) => {
  let convertedStr = [];
  for (const key in obj) {
      let value = obj[key];
      // Skip unwanted keys
      if (key === "_id" || key === "id" || key === "preferredHospital" || key === "expiry") {
          continue;
      }        
      // Handle `docUrl` case
      else if (key === "docUrl") {
          // If `value` is not null, append `SaSToken` as a query parameter
          value = value ? `${value}?${sasToken}` : "null";
      }
      // Other keys
      else {
          // Format other values
          value = value !== null ? `"${value}"` : "null";
      }
      // Push the formatted key-value pair to `convertedStr`
      convertedStr.push(`${key}:${value}`);
  }
  // Join the array of formatted strings into a single comma-separated string
  return convertedStr.join(",");
};


const gettingKeyFromObj = (obj: any) => {
  const keys = Object.keys(obj);
  return keys;
};
const gettingValueFromObj = (obj: any) => {
  const values = Object.values(obj);
  return values;
};

function getUniqueArray(arr: any[]) {
  const flattenedArray = arr.flat();
  // Use Set to get unique values
  const uniqueValues = Array.from(new Set(flattenedArray));

  // Custom sorting function for specific keys
  const customSort = (a: string, b: string): number => {
      const specialKeysOrder = ["dateOfBirth", "email", "fullName", "gender", "mobile", "relationshipToSubscriber", "whatsappMobile"];
      const aIndex = specialKeysOrder.indexOf(a);
      const bIndex = specialKeysOrder.indexOf(b);

      // If both keys are in the specialKeysOrder array, compare their positions
      if (aIndex !== -1 && bIndex !== -1) {
          return aIndex - bIndex;
      }

      // If only one of the keys is in the specialKeysOrder array, prioritize it
      return aIndex !== -1 ? -1 : (bIndex !== -1 ? 1 : 0);
  };

  uniqueValues.sort((a, b) => {
      const [aFirstPart] = a.split(".");
      const [bFirstPart] = b.split(".");        
      // Use customSort for specific keys, otherwise, use localeCompare
      return customSort(aFirstPart, bFirstPart) || aFirstPart.localeCompare(bFirstPart);
  });

  let removeUnwantedField=uniqueValues.filter(el=>el!=="address"&&el!=="EmContact"&&el!=="preferredHospital"&&el!=="insurance")
  for(let i=0; i<removeUnwantedField.length; i++){
    if(removeUnwantedField[i]==="fullName"){
      removeUnwantedField[i]="benName"
  
    }if(removeUnwantedField[i]==="mobile"){
      removeUnwantedField[i]="benMobile"
    }if(removeUnwantedField[i]==="whatsappMobile"){
      removeUnwantedField[i]="benWhatsappBenMobile"
    }
  }
  return removeUnwantedField;
}
function convertArrayToObject(arrayOfObj:any){
  // eslint-disable-next-line
const res=  arrayOfObj.map((fields:any,i:number)=>{
     // console.log("1111111",fields);
     if(fields.hasOwnProperty("mobile")||fields.hasOwnProperty("whatsappMobile")){
       return {
         ...fields,
         mobile: fields?.mobile?.number || "null",
         whatsappMobile: fields?.whatsappMobile?.number || "null"
       }
     }
   })
   return res

 }

export const exportBenDataByFilter = async (bandData: any,sasToken:any) => {
  console.log('sasToken:--------', sasToken);
  if(!sasToken){

    (async()=>await generateSaSToken())();
    sasToken=localStorage.getItem("sasToken")
  }
  let headers: any = [];
let values: any = [];
  // console.log('bandData:', bandData);
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Sheet 1");
  await constructDynamicTableField(bandData,headers,values,sasToken);
  //
  const gettinguniqHeaders = getUniqueArray(headers);
  //headers//
  worksheet.addRow(gettinguniqHeaders);
  //rowValues//
  values.length &&
    values.forEach((item: any) => {
      const rowValues = gettinguniqHeaders.map((key: any) =>
        item.find((el: any) => el[key])
          ? item.find((el: any) => el[key])[key]
          : "N/A"
      );
      worksheet.addRow(rowValues);
    });
  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Trigger file download
    saveAs(blob, "BenAddressData.xlsx");
  });
};
