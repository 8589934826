import { deleteUser, searchQuery } from "../../Http/Index"

export const deleteUserData = async (payload: any) => {
    try {
        await deleteUser(payload)
        // console.log(data)
        alert("User Deleted Successfully")
    }
    catch (error: any) {
        console.log(error)
        alert(error.response?.data?.message)
    }
    return true
}


export const searchQueryFun = async (payload: any) => {
    try {
        let response = await searchQuery(payload)
        // console.log(response)
        return response.data
    } catch (error) {
        console.log(error)
        alert(error)
    }
}