import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getJobByJobId, getManyBenData } from "../WaMessageDetails/Action/action";
import { getDeliveryStatus } from "./Action/action";
import { Layout } from "../../GlobalComponent/Layout/Layout";
import { DynamicTable } from "../../GlobalComponent/CustomTable/DynamicTable";
import { CustomCard } from "../../GlobalComponent/Card/Card";
import { getTemplateByVariable, getVariable } from "../../Helper/waTemplateVariables";
import { CustomButton } from "../../GlobalComponent/CustomButton/CustomButton";
import { handleBuildPayloadForWaMsg } from "../SendBulkWaMessage/Action/actions";
import style from "./wrapper.module.css"

export const WaResponseWrapper: React.FC = () => {
  const { jobId } = useParams();
  const navigate=useNavigate();
  const [data,setData]=useState<any>({
    headers:[],
    benData:[]
  });
  const [cardData, setCardData] = useState<any>({});
  const [jobData, setJobData] = useState<any>([])
  const [isMsgFailed, setIsMsgFailed] = useState<boolean>(false);
  const [failedMsg, setFailedMsg] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isResend, setIsResend] = useState<any>();



  // console.log('jobId:', jobId);
  useEffect(() => {
    getJobByJobId(jobId)
      .then(async (res: any) => {
        // console.log("res:", res);
        setJobData(res);
        const deliveryData=await getDeliveryStatus({waJobData:res,jobId,navigate});
        // console.log('deliveryData:', deliveryData)
        setCardData(deliveryData.deliveryCount);
        if(deliveryData?.combinedData?.length){
            let headers=Object.keys(deliveryData?.combinedData[0]);
            // console.log('headers:', headers,deliveryData);
            setData({headers,benData:deliveryData?.combinedData});
            const getFailedMsgData=deliveryData.combinedData.filter((item:any)=>item?.deliveryStatus?.toLowerCase()==='failed'||item?.deliveryStatus?.toLowerCase()==='in_progress'||item?.deliveryStatus?.toLowerCase()==='accepted');
            setFailedMsg(getFailedMsgData);
            ////i got ben Data now i want templateName,variable,jobData////
            if(getFailedMsgData.length){
              setIsMsgFailed(true);
            }else{
              setIsMsgFailed(false);
            }


            //if suppose any one message was failed then here we going to give option for resend button in the top once they clicked it wil again send
            //even it is failed???
            //or we can give option to resend all failed messages
            //here need to find out by delivery status and need to update
            

          }
      })
      .catch((e: any) => {
        alert(e?.response?.message || "Check");
        console.log(e);
      });
       // eslint-disable-next-line
  }, [jobId,isResend]);
  console.log('jobData:', jobData);
const handleNavigate=(jobId:any)=>{
    // console.log("check",jobId)    
}
const resendMsg=async()=>{
  //getVariable//
  const storedData = sessionStorage.getItem("customInput");
let customInput;
  if (storedData !== null) {
      customInput = JSON.parse(storedData);
  } else {
      // If the item is not in sessionStorage, use a default value
      customInput = {}; // You can change this to your preferred default value
  }

    const getBenData = await getManyBenData(failedMsg);
    const templateVariable = await getVariable(jobData.templateName);
    const selectedTempDetails = await getTemplateByVariable({ serviceName: "api-whatsapp-service", templateName: jobData.templateName })
    // console.log("dddd",data);
    const res = await handleBuildPayloadForWaMsg({ benData: getBenData, templateName: jobData?.templateName, templateVariable: templateVariable, setIsLoading, isLoading, jobData: jobData.jobData, jobId, customInput, btnType: "Resend", templateData: selectedTempDetails?.data });
    setIsResend(res)

}
  return (
    <Layout>
    <div>
      <h1>Whatsapp ResponseDetails-{jobData?.jobName||"jobName Not Found"}</h1>
    
    </div>
    <div style={{marginTop:"20px",marginBottom:"20px",width:"20%",marginLeft:"1rem"}}>
    {cardData&&<CustomCard data={cardData}/>}
    </div>
    <div style={{marginTop:"20px",marginBottom:"20px",width:"20%",marginLeft:"1rem"}}>
      {isMsgFailed&& <CustomButton handleClick={resendMsg} label="Resend All Failed Messages" additionalData=""   customStyle={style.button}/>}
    </div>
    <div>
    <DynamicTable
        handleClick={handleNavigate}
        headers={data?.headers}
        benData={data?.benData}
      />
    </div>
    </Layout>
  );
};
