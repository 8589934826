import React, { useState } from "react";
import { Input, Select, Button, Box, FormControl, FormLabel } from "@chakra-ui/react";
import { addCompany } from "../../../Http/Index";

const AddData = () => {
  const [formData, setFormData] = useState({
    companyName: "",
    subscriptionType: "",
    logo: "",
    webContext: "",
    emailPattern: ""
  });

  const handleSelection = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const getCurrentDate = (): string => {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, '0'); 
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const year = date.getFullYear();
  
    return `${day}-${month}-${year}`; 
  };

  const handleSubmit = async () => {
    const urlPattern = /^(https?:\/\/)?([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,})(:[0-9]{1,5})?(\/[^\s]*)?$/;

    if (!urlPattern.test(formData.logo) && formData.logo !== "") {
      alert("Please enter a valid Logo URL.");
      return;
    }
    if (!formData["companyName"]) {
      alert(`Please fill in the company name.`);
      return;
    }
    if (!formData["subscriptionType"]) {
      alert(`Please fill in the subscription type.`);
      return;
    }
    
    let payload = {
      ...formData,
      dateOfPartnership: getCurrentDate(),
      isActivePartnership: true,
      isDeleted: false,
    };

    try {
      let response = await addCompany(payload);
      if (response?.data?.status === 201) {
        alert(response?.data?.message);
        setFormData({
          companyName: "",
          subscriptionType: "",
          logo: "",
          webContext: "",
          emailPattern: ""
        });
      } else {
        alert("Error occurred while creating company");
      }
    } catch (err) {
      alert("Error occurred while making request");
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", marginRight: "20px", gap: "10px", marginTop: "30px" }}>
      <div style={{ display: "flex", gap: "10px" }}>
        <FormControl id="companyName" isRequired>
          <FormLabel>Company Name</FormLabel>
          <Input 
            placeholder="Enter Company Name" 
            name="companyName"  
            value={formData.companyName} 
            onChange={handleSelection} 
          />
         
        </FormControl>

        <FormControl id="subscriptionType" isRequired>
          <FormLabel>Subscription Type</FormLabel>
          <Select
            name="subscriptionType"
            value={formData.subscriptionType}
            onChange={handleSelection}
          >
            <option value="">Select Type</option>
            <option value="BASIC">Basic</option>
            <option value="HOSPITAL">Hospital</option>
            <option value="INSURANCE">Insurance</option>
            <option value="ENTERPRISE">Enterprise</option>
          </Select>
         
        </FormControl>

        <FormControl id="logo">
          <FormLabel>Logo URL</FormLabel>
          <Input 
            placeholder="Enter Logo URL" 
            name="logo"  
            value={formData.logo} 
            onChange={handleSelection}  
          />
        </FormControl>

        <FormControl id="webContext">
          <FormLabel>Web Context</FormLabel>
          <Input 
            placeholder="Enter Web Context" 
            name="webContext"   
            value={formData.webContext} 
            onChange={handleSelection} 
          />
        </FormControl>

        <FormControl id="emailPattern">
          <FormLabel>Email Pattern</FormLabel>
          <Input 
            placeholder="Enter Email Pattern" 
            name="emailPattern"  
            value={formData.emailPattern} 
            onChange={handleSelection} 
          />
        </FormControl>
      </div>

      <Box marginTop="10px" display="flex" justifyContent="center">
        <Button colorScheme="green" onClick={handleSubmit}>
          Add Company
        </Button>
      </Box>
    </div>
  );
};

export default AddData;
