import { CustomButton } from "../../GlobalComponent/CustomButton/CustomButton";
import { CustomInput } from "../../GlobalComponent/CustomInput/CustomeInput";
import { CustomModal } from "../../GlobalComponent/CustomModal/CustomModal";
import style from "./SendBulkWaMessaage.module.css";
import { UseCustomHook } from "../../CustomHook/UseCustomHook";
import { handleCreateJob } from "./Action/actions";
interface ModalInterface {
  modalTitle: any;
  handleCloseModal: any;
  updateList: any;
}

export const ModalWrapper: React.FC<ModalInterface> = ({
  modalTitle,
  handleCloseModal,
  updateList
}: any) => {
  const { catchInput, updateInput } = UseCustomHook("");

  const onChange = ({label,value}:any) => {
    updateInput(value);
  };
  return (
    <>
      <CustomModal modalTitle={modalTitle} handleCloseModal={handleCloseModal}>
        <div className={style.child}>
          <div>
            <CustomInput label="Job Name" value={catchInput} onChange={onChange} />
          </div>
          <div className={style.center}>
            <CustomButton
              additionalData={{catchInput,handleCloseModal,updateList}}
              label={"Save Job"}
              handleClick={handleCreateJob}
              customStyle={style.customBtnStyle}
            />
          </div>
        </div>
      </CustomModal>
    </>
  );
};
