// import React from 'react';
import './App.css';
import MainRoutes from './Routes/MainRoutes/MainRoutes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ApolloProvider } from '@apollo/client';
import { client } from './Http/Index';
import { QueryClient, QueryClientProvider } from 'react-query';
function App() {
  const queryClient = new QueryClient()
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ApolloProvider client={client}>
          <MainRoutes />
          <ToastContainer autoClose={3000} />
        </ApolloProvider>
      </QueryClientProvider>

    </>
  );
}

export default App;
