import React, { useCallback, useEffect, useState } from "react";
import style from "./CustomeTable.module.css";
import { flattenObject, getRemovedBenId } from "./utils";
import { useLocation } from "react-router-dom";
interface CustomerTableProps {
  data: any;
  searchRef: React.RefObject<HTMLButtonElement>;
  setSelectedBenList: any;

  //here ima going to pass variable if it is true table should be selectable;
}

const CustomTable: React.FC<CustomerTableProps> = ({
  data,
  searchRef,
  setSelectedBenList,
}) => {
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [selectedTableData, setSelectedData] = useState<any>([]);
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
  const [isTableSelected, setIsTableSelected] = useState<string>("");
  const [gettingUniqHeaders,setGettingHeader]=useState([]);
  const [gettingUniqValues,setGettingValues]=useState([]);
  //eslint-disable-next-line
  const [SaSToken, setSaSToken] = useState(localStorage.getItem("sasToken"));

  let headers: any = [];
  let values: any = [];
  const location = useLocation();
  // console.log("location:", location);
  // let SaSToken = localStorage.getItem("sasToken");
  // console.log('SaSToken:--1', SaSToken);
  const constructDynamicTableField = useCallback( () => {
 
    data.forEach((el: any, index: number) => {
      // console.log('el:', el._id)
      let tableValues = [];
      let tableHeaders = [];
      if (el._id) {
        tableHeaders.push("benId");
        tableValues.push({ benId: el._id });
      }
      if (el.personalInfo) {
        let keys = gettingKeyFromObj(el.personalInfo);
        tableHeaders.push(...keys);
        tableValues.push({
          ...(el?.personalInfo?.fullName
            ? { benName: el.personalInfo.fullName }
            : {}),
          ...(el?.personalInfo?.dateOfBirth
            ? { dateOfBirth: el.personalInfo.dateOfBirth }
            : {}),
          ...(el?.personalInfo?.email ? { email: el.personalInfo.email } : {}),
          ...(el?.subscriberId ? { subscriberId: el.subscriberId } : {}),
          ...(el?.personalInfo?.image ? { image: el.personalInfo.image } : {}),
          ...(el?.personalInfo?.mobile?.number
            ? { benMobile: el.personalInfo.mobile.number }
            : {}),
          ...(el?.personalInfo?.whatsappMobile?.number
            ? { benWhatsappMobile: el.personalInfo.whatsappMobile.number }
            : {}),
          ...(el?.personalInfo?.relationshipToSubscriber
            ? {
                relationshipToSubscriber:
                  el.personalInfo.relationshipToSubscriber,
              }
            : {}),
          ...(el?.personalInfo?.gender
            ? { gender: el.personalInfo.gender }
            : {}),
        });
      }
      if (el.address) {
        // alert("check");
        const res = createPayloadForArray("address", el.address);
        // console.log("res:", res);
        tableHeaders.push(...res.parentName);
        tableValues.push(...res.arrayPayload);
        el.address.forEach((addressInside: any) => {
          if (addressInside.preferredHospital) {
            const res = createPayloadForArray(
              "preferredHospital",
              addressInside.preferredHospital
            );
            tableHeaders.push(...res.parentName);
            tableValues.push(...res.arrayPayload);
          }
        });
      }
      if (el.emergencyContacts) {
        const getKeyValue = convertArrayToObject(el.emergencyContacts);
        // console.log("check", getKeyValue);
        const res = createPayloadForArray("EmContact", getKeyValue);
        // console.log("res:", res);
        tableHeaders.push(...res.parentName);
        tableValues.push(...res.arrayPayload);
      }
      if (el.insurancePolicies) {
       
        const res = createPayloadForArray("insurance", el.insurancePolicies);
        tableHeaders.push(...res.parentName);
        tableValues.push(...res.arrayPayload);
      }
      // console.log("@@@@@",el);
      if (el.company || el.company === null) {
        // console.log("companyyyyyyy",el.company)
        if (el.company) {
          let values = gettingValueFromObj(el?.company);
          tableHeaders.push("companyName");
          tableValues.push({ companyName: values });
        } else {
          tableHeaders.push("companyName");
          tableValues.push({ companyName: "null" });
        }
      }
      if (el.subscriberDetails) {
        let keys = gettingKeyFromObj(el.subscriberDetails);
        tableHeaders.push(...keys);
        tableValues.push(el.subscriberDetails);
      }
      if (el.address && el.personalInfo && el.personalInfo.mobile) {
        // console.log("check", el.personalInfo.mobile);
        const res = isActivated({
          address: el.address,
          personalInfo: el.personalInfo,
        });
        console.log("res:", res);
        tableHeaders.push("isActivated");
        tableValues.push({ isActivated: res||"0" });
      }
      // if(el.medicalInfo){
      //   console.log(el.medicalInfo);
      //   tableHeaders.push("medicalInfo");

      // }
      tableValues.push({ isDeleted: el?.isDeleted });
      values.push(tableValues);
      headers.push(tableHeaders);
      // console.log('values:', values);
    });
    //eslint-disable-next-line
  },[data,isAllSelected]);

  const createPayloadForArray = (fieldName: string, data: any) => {
    // console.log('data:', data);
    let arrayPayload: any = [];
    let parentName: any = [];
    data?.length &&
      data.forEach((el: any, index: number) => {
        if (el) {
          const field = convertObjectToString(el);
          // console.log('field:', field);
          parentName[index] = `${fieldName}.${index + 1}`;
          let currentName = `${fieldName}.${index + 1}`;
          arrayPayload[index] = { [currentName]: field };
        }
      });
    if (!parentName.length) {
      parentName.push(fieldName);
    }
    if (!arrayPayload.length) {
      arrayPayload.push({ [fieldName]: `No ${fieldName}` });
    }
    // console.log("arrayPayload:", arrayPayload);
    return { parentName, arrayPayload };
  };

  const convertObjectToString = (obj: any) => {
    let convertedStr = [];
    for (const key in obj) {
        let value = obj[key];
        // Skip unwanted keys
        if (key === "_id" || key === "id" || key === "preferredHospital" || key === "expiry") {
            continue;
        }        
        // Handle `docUrl` case
        else if (key === "docUrl") {
            // If `value` is not null, append `SaSToken` as a query parameter
            value = value ? `${value}?${SaSToken}` : "null";
        }
        // Other keys
        else {
            // Format other values
            value = value !== null ? `"${value}"` : "null";
        }
        // Push the formatted key-value pair to `convertedStr`
        convertedStr.push(`${key}:${value}`);
    }
    // Join the array of formatted strings into a single comma-separated string
    return convertedStr.join(",");
};


  const gettingKeyFromObj = (obj: any) => {
    const keys = Object.keys(obj);
    return keys;
  };
  const gettingValueFromObj = (obj: any) => {
    const values = Object.values(obj);
    return values;
  };
  // constructDynamicTableField();
  
  // console.log("gettinguniqHeaders:", gettingUniqHeaders);
  // console.log("values:", values);

  function getUniqueArray(arr: any[]) {
    console.log("headers:", headers);
    const flattenedArray = arr.flat();
    // Use Set to get unique values
    const uniqueValues = Array.from(new Set(flattenedArray));

    // Custom sorting function for specific keys
    const customSort = (a: string, b: string): number => {
      const specialKeysOrder = [
        "dateOfBirth",
        "email",
        "fullName",
        "gender",
        "mobile",
        "relationshipToSubscriber",
        "whatsappMobile",
      ];
      const aIndex = specialKeysOrder.indexOf(a);
      const bIndex = specialKeysOrder.indexOf(b);

      // If both keys are in the specialKeysOrder array, compare their positions
      if (aIndex !== -1 && bIndex !== -1) {
        return aIndex - bIndex;
      }

      // If only one of the keys is in the specialKeysOrder array, prioritize it
      return aIndex !== -1 ? -1 : bIndex !== -1 ? 1 : 0;
    };

    uniqueValues.sort((a, b) => {
      const [aFirstPart] = a.split(".");
      const [bFirstPart] = b.split(".");
      // Use customSort for specific keys, otherwise, use localeCompare
      return (
        customSort(aFirstPart, bFirstPart) ||
        aFirstPart.localeCompare(bFirstPart)
      );
    });

    // let removeUnwantedField = uniqueValues.filter(
    //   (el) =>
    //     el !== "address" &&
    //     el !== "EmContact" &&
    //     el !== "preferredHospital" &&
    //     el !== "insurance"
    // );
    for (let i = 0; i < uniqueValues.length; i++) {
      if (uniqueValues[i] === "fullName") {
        uniqueValues[i] = "benName";
      }
      if (uniqueValues[i] === "mobile") {
        uniqueValues[i] = "benMobile";
      }
      if (uniqueValues[i] === "whatsappMobile") {
        uniqueValues[i] = "benWhatsappMobile";
      }
    }
   
    return uniqueValues;
  }

  function convertArrayToObject(arrayOfObj: any) {
    // eslint-disable-next-line
    const res = arrayOfObj.map((fields: any, i: number) => {
      // console.log("1111111",fields);
      if (
        fields.hasOwnProperty("mobile") ||
        fields.hasOwnProperty("whatsappMobile")
      ) {
        return {
          ...fields,
          mobile: fields?.mobile?.number || "null",
          whatsappMobile: fields?.whatsappMobile?.number || "null",
        };
      }
    });
    return res;
  }

  function isActivated({ address, personalInfo }: any) {
    // console.log("personalInfo:", personalInfo);
    let res = false;
    address.forEach((addressFields: any) => {
      // console.log(addressFields, "dfdfd");
      if (
        (addressFields.city !== "" && addressFields.city !== null) ||
        typeof addressFields.pinCode == "number"
      ) {
        res =
          personalInfo?.fullName && personalInfo?.mobile?.number
            ? (res = true)
            : (res = false);
      }
      if (res) {
        return;
      }
    });
    return res ? 1 : 0;
  }

  const toggleAllCheckbox = () => {
    const getTableData = [...selectedTableData];
    setIsAllSelected(!isAllSelected);
    setSelectedRows(isAllSelected ? [] : data.map((_: any, i: number) => i));
    // console.log('data:', data)
    isAllSelected
      ? (getTableData.length = 0)
      : data.forEach((benData: any) => {
          let mergeObject = flattenObject(benData);
          getTableData.push(mergeObject);
        });
    // localStorage.setItem("benDataForWaMsg", JSON.stringify(getTableData));
    setSelectedData(getTableData);
    setSelectedBenList(getTableData);
  };

  const toggleCheckbox = (rowIndex: any, benData: any) => {
    // localStorage.removeItem("benDataForWaMsg");
    const getSelectedRow = [...selectedRows];
    const getTableData = [...selectedTableData];
    // console.log('benData:', benData);
    if (getSelectedRow.includes(rowIndex)) {
      getSelectedRow.splice(getSelectedRow.indexOf(rowIndex), 1);
      // console.log('benData:', benData);
      const getRemovedIndex = getRemovedBenId(getTableData, benData[0].benId);
      // console.log('getRemovedIndex:', getRemovedIndex);
      getTableData.splice(getRemovedIndex, 1);
    } else {
      getSelectedRow.push(rowIndex);
      let mergeObject = Object.assign({}, ...benData);
      getTableData.push(mergeObject);
    }

    // localStorage.setItem("benDataForWaMsg", JSON.stringify(getTableData));
    setSelectedData(getTableData);
    setSelectedBenList(getTableData);
    setSelectedRows(getSelectedRow);
  };

  useEffect(()=>{
    setIsTableSelected(location?.pathname);
  constructDynamicTableField()
  const gettingUniqHeaders: any = getUniqueArray(headers);
  setGettingHeader(gettingUniqHeaders);
  setGettingValues(values);
  // console.log('gettingUniqHeaders:', gettingUniqHeaders);

    //eslint-disable-next-line
  },[data, constructDynamicTableField])
  // console.log('selectedRows:', selectedTableData);
  console.log("values:", values, location?.pathname);
  return (
    <div className={style.table_container}>
      <table>
        <thead className={style.tableHeader}>
          <tr>
            {isTableSelected!=="/print_ben_address" && (
              <th>
                <input
                  type="checkbox"
                  style={{ cursor: "pointer" }}
                  checked={isAllSelected}
                  onChange={toggleAllCheckbox}
                />
                <span style={{ marginLeft: "0.5rem" }}>Select All</span>
              </th>
            )}
            {gettingUniqHeaders.length &&
              gettingUniqHeaders.map((el: any, i: number) => {
                return <th key={el}>{el}</th>;
              })}
          </tr>
        </thead>
        <tbody>
          {gettingUniqValues.length &&
            gettingUniqValues.map((item: any, i: number) => (
              <tr key={`${item}+${i}`}>
                {isTableSelected !== "/print_ben_address" && (
                  <td>
                    <input
                      style={{ cursor: "pointer" }}
                      type="checkbox"
                      checked={selectedRows.includes(i)}
                      onChange={() => toggleCheckbox(i, item)}
                    />
                  </td>
                )}

                {gettingUniqHeaders.map((key: any, index: number) => (
                     <td key={`${key + i}`}>
                     {key === "isActivated" && !item.find((el: any) => el[key]) ? "0" :
                         item.find((el: any) => el[key]) ? item.find((el: any) => el[key])[key] : "null"}
                                         </td>
             
                ))}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default CustomTable;
