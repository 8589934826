import { UseFetchData } from "../../CustomHook/UseFetchData";
import { Layout } from "../../GlobalComponent/Layout/Layout";
import { getAllWaJobs } from "../SendBulkWaMessage/Action/actions";
import { TableWrapper } from "../SendBulkWaMessage/tableWrapper";

export const WaResJobWrapper: React.FC = () => {
   // eslint-disable-next-line
  let { data, isLoading, error, refetch } = UseFetchData(getAllWaJobs);
  // console.log('data:', data);
  return (
    <Layout>
    <div>
      <h1>Whatsapp Job List</h1>
      <div style={{ marginTop: "20px" }}>
        {data && <TableWrapper data={data} component="wa_response" />}
      </div>
    </div>
    </Layout>
  );
};
