import { whatsappConfiguration } from "../../../Http/Index";
export const getWaTemplates = async () => {
  try {
    const res = await whatsappConfiguration();
    return res.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};
