import { useEffect, useState } from "react";
import { Layout } from "../../GlobalComponent/Layout/Layout";
import { getDailyBenData } from "./Action/action";
import { CardWrapper } from "./CardWrapper/CardWrapper";
import style from "./dashBoard.module.css";
import { DynamicTableWrapper } from "./DynamicTableWrapper/DynamicTableWrapper";
import { CustomSelect } from "../../GlobalComponent/CustomSelect/CustomSelect";
import { getCompanyList } from "../BenAddressData/Action/getAllCompany";
import { generateSaSToken } from "../../Helper/helper";

export const DashBoard = () => {
  const [data, setBenData] = useState<any>();
  const [companyList, setCompanyList] = useState([]);
  const [companySelected, setCompanySelected] = useState("all");
  const getallCompany = async () => {
    try {
      const getCompany = await getCompanyList();
      const companyObj = arrayToObject(getCompany?.data);
      setCompanyList(companyObj);
    } catch (e: any) {
      alert(
        e?.response?.message || "Some Technical Issue in getting Templates"
      );
      setBenData("");
    }
  };
  const arrayToObject = (getCompany: any) => {
    const companyListObj: any = {};

    getCompany.forEach((el: any, i: number) => {
      companyListObj[el.companyName] = el.companyName;
    });
    return companyListObj;
  };
  useEffect(() => {
    (async()=>await generateSaSToken())();
    // console.log('companySelected:', companySelected);
    getDailyBenData(companySelected||"all", null, null)
      .then((res) => {
        // console.log("res:", res);
        setBenData(res[0]);
      })
      .catch((e) => {
        console.log(" e:", e);
        if (e?.response?.data?.statusCode === 404) {          
            alert(e?.response?.data?.message);
            setBenData("");          
        }else{
          setBenData("");
          alert("Something went wrong");
        }
      });
  }, [companySelected]);
  // console.log("data:", data);
  return (
    <Layout>
      <div>
        <div className={style.center}>
          <h1>Ben-Dashboard-Details</h1>
        </div>
        <div>
          <div>
            <label>Select Company:</label>
            <CustomSelect
              label="Select Company"
              getOptionList={getallCompany}
              optionList={companyList}
              setCompanySelected={setCompanySelected}
            />
          </div>
        </div>
        {data && Object.keys(data).length ? (
          <CardWrapper data={data} />
        ) : (
          "No Data Found"
        )}
        {data && Object.keys(data).length ? (
          <DynamicTableWrapper data={data} />
        ) : (
          ""
        )}
        {/* <CustomCard /> */}
      </div>
    </Layout>
  );
};
