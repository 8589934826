
import {
  Box,
  Flex,
  Heading,
} from "@chakra-ui/react";
import MicrosoftLogin from "./MicrosoftLogin";

const Login = () => {
  return (
    <>
      <Flex
        width="full"
        align="center"
        justifyContent="center"
        height={"100vh"}
      >
        <Box width="29%" boxShadow="2xl" p="6" rounded="md" bg="white">
          <Box>
            <Heading textAlign="center">Admin Login</Heading>
          </Box>
          <Box mt="1.5rem" textAlign="center">
            <Box>
              <MicrosoftLogin />
            </Box>
          </Box>
        </Box>
      </Flex>
    </>
  );
};

 export default Login;

// import {
//   Box,
//   Button,
//   Flex,
//   FormControl,
//   FormLabel,
//   Heading,
//   Input,
//   Link,
// } from "@chakra-ui/react";
// import { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";

// import MicrosoftLogin from "./MicrosoftLogin";
// import { loginFn } from "./Action/Login";
// import { generateSaSToken } from "../../Helper/helper";
// // import { generateSaSToken } from "../../Helper/generateSasToken";
// const AgentLogin = () => {
//   const [agentLogin, setAgentLoginData] = useState({
//     email: "",
//     password: "",
//   });
//   const navigate = useNavigate();

//   const handleChange = (e: any) => {
//     const { name, value } = e.target;
//     setAgentLoginData({ ...agentLogin, [name]: value });
//   };

//   const handleSubmit = async (e: any) => {
//     e.preventDefault();
//     if (agentLogin.email === "" || agentLogin.password === "") {
//       return alert("Please Enter Email && Password");
//     }
//     try {
//       const agentDet = await loginFn(agentLogin);
//       const agentLocalPayload = {
//         userId: agentDet.userId,
//         name: agentDet.name,
//         email: agentDet.email,
//         mobile: agentDet.mobileNo,
//       };
//       // localStorage.setItem("isLogin", "true");
//       localStorage.setItem("agentData", JSON.stringify(agentLocalPayload));
      
//       setAgentLoginData({
//         email: "",
//         password: "",
//       });
//       generateSaSToken();
//       return navigate("/");
//     } catch (e: any) {
//       alert(
//         e?.response?.data?.message ||
//           "Some Technical Issue in getting Agent Login"
//       );
//       // localStorage.setItem("isLogin", "false");
//     }
//   };

//   useEffect(() => {
//     // let isLogin = localStorage.getItem("isLogin");

//     // if (isLogin === "true") {
//     //   return navigate("/ticket");
//     // } else {
//     //   return navigate("/login");
//     // }
//     // eslint-disable-next-line
//   }, []);

//   return (
//     <Flex width="full" align="center" justifyContent="center">
//       <Box
//         width={{ base: "95%", md: "29%" }}
//         boxShadow="2xl"
//         p="6"
//         rounded="md"
//         bg="white"
//       >
//         <Box>
//           <Heading textAlign="center">Agent Login</Heading>
//         </Box>
//         <Box mt="30px">
//           <form action="" onSubmit={handleSubmit}>
//             <FormControl>
//               <FormLabel>Email</FormLabel>
//               <Input
//                 name="email"
//                 type="email"
//                 id="email"
//                 value={agentLogin.email}
//                 onChange={handleChange}
//                 placeholder="Email"
//               />
//               <FormLabel mt="16px">Password</FormLabel>
//               <Input
//                 id="password"
//                 name="password"
//                 type="password"
//                 value={agentLogin.password}
//                 onChange={handleChange}
//                 placeholder="Password"
//               />
//               <br />
//               <Box>
//                 <Link fontSize={"15px"} href="/forgotPassword" color="red">
//                   Forgot Password
//                 </Link>{" "}
//               </Box>
//               <Box mt="0.5rem" textAlign="center">
//                 <Button
//                   color="white"
//                   bg="rgb(213,63,140)"
//                   width="100%"
//                   type="submit"
//                   id="login-button"
//                 >
//                   Login with Pococare
//                 </Button>
//               </Box>
//             </FormControl>
//           </form>
//           <Box>
//           <Box mt="1.5rem" textAlign="center">
//                 <Box>
//                 <MicrosoftLogin/>
//                 </Box>
//               </Box>
//           </Box>
//         </Box>
//       </Box>
//     </Flex>
//   );
// };

// export default AgentLogin;