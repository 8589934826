
import style from "./SendBulkWaMessaage.module.css";
import { Layout } from "../../GlobalComponent/Layout/Layout";
import { CustomButton } from "../../GlobalComponent/CustomButton/CustomButton";
import { getAllWaJobs, handleCreateJobBtnClick } from "./Action/actions";
import { useState } from "react";
import { UseFetchData } from "../../CustomHook/UseFetchData";
import { TableWrapper } from "./tableWrapper";
import { ModalWrapper } from "./ModalWrapper";
export const SendBulkWaMessage = () => {
  const [isModalOpen, setIsModalOpen] = useState<Boolean>(false);
  //eslint-disable-next-line
  let {data,isLoading,error,refetch}=UseFetchData(getAllWaJobs);  
  // console.log('data:', data)

  return (
    <>
      <Layout>
        <div>
          <CustomButton
            label="Create Job"
            additionalData={{setIsModalOpen, isModalOpen}}
            customStyle={style.customBtnStyle}
            handleClick={handleCreateJobBtnClick}
          />
        </div>
        <div style={{marginTop:"20px"}}>
         {
          data && <TableWrapper data={data}/>
         }
        </div>
        {
          isModalOpen && <ModalWrapper modalTitle="Create Job" handleCloseModal={() => setIsModalOpen(false)} updateList={refetch}/>
        }
      </Layout>
    </>
  );
};
