import { useDisclosure } from "@chakra-ui/hooks";
import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";


import CheckboxTree from "react-checkbox-tree";
import {  Center } from "@chakra-ui/react";

interface CustomPopupProps {
  modalTitle: string;
  handleCloseModal: () => void;
  selectedFields: any;
  handleCheckboxChange: any;
  setCheckedTree: any;
  handleCancelPopup: any;
  checkedTree: any;
  expandedTree: any;
  setExpandedTree: any;
}

export const CustomPopup: React.FC<CustomPopupProps> = ({
  modalTitle,
  handleCloseModal,
  selectedFields,
  setCheckedTree,
  expandedTree,
  setExpandedTree,
  checkedTree,
}) => {
  // eslint-disable-next-line
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleClose = () => {
    handleCloseModal();
    onClose();
    
  };
  return (
    <>
      <Modal size={"md"} isOpen={true} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{modalTitle}</ModalHeader>
          <ModalCloseButton />
          {
            <Center >
              <CheckboxTree
                icons={{
                  parentClose: <span className="rct-icon rct-icon-leaf" />,
                  parentOpen:  <span className="rct-icon rct-icon-leaf" />,
              }}
                nodes={selectedFields}
                checked={checkedTree}
                expanded={expandedTree}
                onCheck={(checkedTree) => setCheckedTree(checkedTree)}
                onExpand={(expandedTree) => setExpandedTree(expandedTree)}
              />
            </Center>
          }
         
        </ModalContent>
      </Modal>
    </>
  );
};
